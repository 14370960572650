// took from https://github.com/richleland/pygments-css/
// $ pygmentize -S default -f html -a .highlight > default.css;


/*light*/
.theme-light {

  pre {
    line-height: 125%;
  }

  td.linenos .normal {
    color: inherit;
    background-color: transparent;
    padding: 0px;
    border: none;
  }

  span.linenos {
    color: inherit;
    background-color: transparent;
    padding: 0px;
  }

  td.linenos .special {
    color: #000000;
    background-color: #ffffc0;
    padding: 0px;
  }

  span.linenos.special {
    color: #000000;
    background-color: #ffffc0;
    padding: 0px;
  }

  .highlight .hll {
    background-color: #ffffcc
  }

  .highlight {
    background: #f8f8f8;
  }

  .highlight .c {
    color: #3D7B7B;
    font-style: italic
  }

  /* Comment */
  .highlight .err {
    border: 1px solid #FF0000
  }

  /* Error */
  .highlight .k {
    color: #008000;
    font-weight: bold
  }

  /* Keyword */
  .highlight .o {
    color: #666666
  }

  /* Operator */
  .highlight .ch {
    color: #3D7B7B;
    font-style: italic
  }

  /* Comment.Hashbang */
  .highlight .cm {
    color: #3D7B7B;
    font-style: italic
  }

  /* Comment.Multiline */
  .highlight .cp {
    color: #9C6500
  }

  /* Comment.Preproc */
  .highlight .cpf {
    color: #3D7B7B;
    font-style: italic
  }

  /* Comment.PreprocFile */
  .highlight .c1 {
    color: #3D7B7B;
    font-style: italic
  }

  /* Comment.Single */
  .highlight .cs {
    color: #3D7B7B;
    font-style: italic
  }

  /* Comment.Special */
  .highlight .gd {
    color: #A00000
  }

  /* Generic.Deleted */
  .highlight .ge {
    font-style: italic
  }

  /* Generic.Emph */
  .highlight .gr {
    color: #E40000
  }

  /* Generic.Error */
  .highlight .gh {
    color: #000080;
    font-weight: bold
  }

  /* Generic.Heading */
  .highlight .gi {
    color: #008400
  }

  /* Generic.Inserted */
  .highlight .go {
    color: #717171
  }

  /* Generic.Output */
  .highlight .gp {
    color: #000080;
    font-weight: bold
  }

  /* Generic.Prompt */
  .highlight .gs {
    font-weight: bold
  }

  /* Generic.Strong */
  .highlight .gu {
    color: #800080;
    font-weight: bold
  }

  /* Generic.Subheading */
  .highlight .gt {
    color: #0044DD
  }

  /* Generic.Traceback */
  .highlight .kc {
    color: #008000;
    font-weight: bold
  }

  /* Keyword.Constant */
  .highlight .kd {
    color: #008000;
    font-weight: bold
  }

  /* Keyword.Declaration */
  .highlight .kn {
    color: #008000;
    font-weight: bold
  }

  /* Keyword.Namespace */
  .highlight .kp {
    color: #008000
  }

  /* Keyword.Pseudo */
  .highlight .kr {
    color: #008000;
    font-weight: bold
  }

  /* Keyword.Reserved */
  .highlight .kt {
    color: #B00040
  }

  /* Keyword.Type */
  .highlight .m {
    color: #666666
  }

  /* Literal.Number */
  .highlight .s {
    color: #BA2121
  }

  /* Literal.String */
  .highlight .na {
    color: #687822
  }

  /* Name.Attribute */
  .highlight .nb {
    color: #008000
  }

  /* Name.Builtin */
  .highlight .nc {
    color: #0000FF;
    font-weight: bold
  }

  /* Name.Class */
  .highlight .no {
    color: #880000
  }

  /* Name.Constant */
  .highlight .nd {
    color: #AA22FF
  }

  /* Name.Decorator */
  .highlight .ni {
    color: #717171;
    font-weight: bold
  }

  /* Name.Entity */
  .highlight .ne {
    color: #CB3F38;
    font-weight: bold
  }

  /* Name.Exception */
  .highlight .nf {
    color: #0000FF
  }

  /* Name.Function */
  .highlight .nl {
    color: #767600
  }

  /* Name.Label */
  .highlight .nn {
    color: #0000FF;
    font-weight: bold
  }

  /* Name.Namespace */
  .highlight .nt {
    color: #008000;
    font-weight: bold
  }

  /* Name.Tag */
  .highlight .nv {
    color: #19177C
  }

  /* Name.Variable */
  .highlight .ow {
    color: #AA22FF;
    font-weight: bold
  }

  /* Operator.Word */
  .highlight .w {
    color: #bbbbbb
  }

  /* Text.Whitespace */
  .highlight .mb {
    color: #666666
  }

  /* Literal.Number.Bin */
  .highlight .mf {
    color: #666666
  }

  /* Literal.Number.Float */
  .highlight .mh {
    color: #666666
  }

  /* Literal.Number.Hex */
  .highlight .mi {
    color: #666666
  }

  /* Literal.Number.Integer */
  .highlight .mo {
    color: #666666
  }

  /* Literal.Number.Oct */
  .highlight .sa {
    color: #BA2121
  }

  /* Literal.String.Affix */
  .highlight .sb {
    color: #BA2121
  }

  /* Literal.String.Backtick */
  .highlight .sc {
    color: #BA2121
  }

  /* Literal.String.Char */
  .highlight .dl {
    color: #BA2121
  }

  /* Literal.String.Delimiter */
  .highlight .sd {
    color: #BA2121;
    font-style: italic
  }

  /* Literal.String.Doc */
  .highlight .s2 {
    color: #BA2121
  }

  /* Literal.String.Double */
  .highlight .se {
    color: #AA5D1F;
    font-weight: bold
  }

  /* Literal.String.Escape */
  .highlight .sh {
    color: #BA2121
  }

  /* Literal.String.Heredoc */
  .highlight .si {
    color: #A45A77;
    font-weight: bold
  }

  /* Literal.String.Interpol */
  .highlight .sx {
    color: #008000
  }

  /* Literal.String.Other */
  .highlight .sr {
    color: #A45A77
  }

  /* Literal.String.Regex */
  .highlight .s1 {
    color: #BA2121
  }

  /* Literal.String.Single */
  .highlight .ss {
    color: #19177C
  }

  /* Literal.String.Symbol */
  .highlight .bp {
    color: #008000
  }

  /* Name.Builtin.Pseudo */
  .highlight .fm {
    color: #0000FF
  }

  /* Name.Function.Magic */
  .highlight .vc {
    color: #19177C
  }

  /* Name.Variable.Class */
  .highlight .vg {
    color: #19177C
  }

  /* Name.Variable.Global */
  .highlight .vi {
    color: #19177C
  }

  /* Name.Variable.Instance */
  .highlight .vm {
    color: #19177C
  }

  /* Name.Variable.Magic */
  .highlight .il {
    color: #666666
  }
}

/* Literal.Number.Integer.Long */

/*dark -------------------------------------------------------------------------------------------------------*/
.theme-dark {


  pre {
    line-height: 125%;
  }

  td.linenos .normal {
    color: inherit;
    background-color: transparent;
    padding-left: 5px;
    padding-right: 5px;
  }

  span.linenos {
    color: inherit;
    background-color: transparent;
    padding-left: 5px;
    padding-right: 5px;
  }

  td.linenos .special {
    color: #000000;
    background-color: #ffffc0;
    padding-left: 5px;
    padding-right: 5px;
  }

  span.linenos.special {
    color: #000000;
    background-color: #595946;
    padding-left: 5px;
    padding-right: 5px;
  }

  .highlight .hll {
    background-color: #595946;
  }

  .highlight {
    background: #282C34;
    color: #ABB2BF
  }

  .highlight .c {
    color: #7F848E
  }

  /* Comment */
  .highlight .err {
    color: #ABB2BF
  }

  /* Error */
  .highlight .esc {
    color: #ABB2BF
  }

  /* Escape */
  .highlight .g {
    color: #ABB2BF
  }

  /* Generic */
  .highlight .k {
    color: #C678DD
  }

  /* Keyword */
  .highlight .l {
    color: #ABB2BF
  }

  /* Literal */
  .highlight .n {
    color: #E06C75
  }

  /* Name */
  .highlight .o {
    color: #56B6C2
  }

  /* Operator */
  .highlight .x {
    color: #ABB2BF
  }

  /* Other */
  .highlight .p {
    color: #ABB2BF
  }

  /* Punctuation */
  .highlight .ch {
    color: #7F848E
  }

  /* Comment.Hashbang */
  .highlight .cm {
    color: #7F848E
  }

  /* Comment.Multiline */
  .highlight .cp {
    color: #7F848E
  }

  /* Comment.Preproc */
  .highlight .cpf {
    color: #7F848E
  }

  /* Comment.PreprocFile */
  .highlight .c1 {
    color: #7F848E
  }

  /* Comment.Single */
  .highlight .cs {
    color: #7F848E
  }

  /* Comment.Special */
  .highlight .gd {
    color: #ABB2BF
  }

  /* Generic.Deleted */
  .highlight .ge {
    color: #ABB2BF
  }

  /* Generic.Emph */
  .highlight .gr {
    color: #ABB2BF
  }

  /* Generic.Error */
  .highlight .gh {
    color: #ABB2BF
  }

  /* Generic.Heading */
  .highlight .gi {
    color: #ABB2BF
  }

  /* Generic.Inserted */
  .highlight .go {
    color: #ABB2BF
  }

  /* Generic.Output */
  .highlight .gp {
    color: #ABB2BF
  }

  /* Generic.Prompt */
  .highlight .gs {
    color: #ABB2BF
  }

  /* Generic.Strong */
  .highlight .gu {
    color: #ABB2BF
  }

  /* Generic.Subheading */
  .highlight .gt {
    color: #ABB2BF
  }

  /* Generic.Traceback */
  .highlight .kc {
    color: #E5C07B
  }

  /* Keyword.Constant */
  .highlight .kd {
    color: #C678DD
  }

  /* Keyword.Declaration */
  .highlight .kn {
    color: #C678DD
  }

  /* Keyword.Namespace */
  .highlight .kp {
    color: #C678DD
  }

  /* Keyword.Pseudo */
  .highlight .kr {
    color: #C678DD
  }

  /* Keyword.Reserved */
  .highlight .kt {
    color: #E5C07B
  }

  /* Keyword.Type */
  .highlight .ld {
    color: #ABB2BF
  }

  /* Literal.Date */
  .highlight .m {
    color: #D19A66
  }

  /* Literal.Number */
  .highlight .s {
    color: #98C379
  }

  /* Literal.String */
  .highlight .na {
    color: #E06C75
  }

  /* Name.Attribute */
  .highlight .nb {
    color: #E5C07B
  }

  /* Name.Builtin */
  .highlight .nc {
    color: #E5C07B
  }

  /* Name.Class */
  .highlight .no {
    color: #E06C75
  }

  /* Name.Constant */
  .highlight .nd {
    color: #61AFEF
  }

  /* Name.Decorator */
  .highlight .ni {
    color: #E06C75
  }

  /* Name.Entity */
  .highlight .ne {
    color: #E06C75
  }

  /* Name.Exception */
  .highlight .nf {
    color: #61AFEF;
    font-weight: bold
  }

  /* Name.Function */
  .highlight .nl {
    color: #E06C75
  }

  /* Name.Label */
  .highlight .nn {
    color: #E06C75
  }

  /* Name.Namespace */
  .highlight .nx {
    color: #E06C75
  }

  /* Name.Other */
  .highlight .py {
    color: #E06C75
  }

  /* Name.Property */
  .highlight .nt {
    color: #E06C75
  }

  /* Name.Tag */
  .highlight .nv {
    color: #E06C75
  }

  /* Name.Variable */
  .highlight .ow {
    color: #56B6C2
  }

  /* Operator.Word */
  .highlight .p-Marker {
    color: #ABB2BF
  }

  /* Punctuation.Marker */
  .highlight .w {
    color: #ABB2BF
  }

  /* Text.Whitespace */
  .highlight .mb {
    color: #D19A66
  }

  /* Literal.Number.Bin */
  .highlight .mf {
    color: #D19A66
  }

  /* Literal.Number.Float */
  .highlight .mh {
    color: #D19A66
  }

  /* Literal.Number.Hex */
  .highlight .mi {
    color: #D19A66
  }

  /* Literal.Number.Integer */
  .highlight .mo {
    color: #D19A66
  }

  /* Literal.Number.Oct */
  .highlight .sa {
    color: #98C379
  }

  /* Literal.String.Affix */
  .highlight .sb {
    color: #98C379
  }

  /* Literal.String.Backtick */
  .highlight .sc {
    color: #98C379
  }

  /* Literal.String.Char */
  .highlight .dl {
    color: #98C379
  }

  /* Literal.String.Delimiter */
  .highlight .sd {
    color: #98C379
  }

  /* Literal.String.Doc */
  .highlight .s2 {
    color: #98C379
  }

  /* Literal.String.Double */
  .highlight .se {
    color: #98C379
  }

  /* Literal.String.Escape */
  .highlight .sh {
    color: #98C379
  }

  /* Literal.String.Heredoc */
  .highlight .si {
    color: #98C379
  }

  /* Literal.String.Interpol */
  .highlight .sx {
    color: #98C379
  }

  /* Literal.String.Other */
  .highlight .sr {
    color: #98C379
  }

  /* Literal.String.Regex */
  .highlight .s1 {
    color: #98C379
  }

  /* Literal.String.Single */
  .highlight .ss {
    color: #98C379
  }

  /* Literal.String.Symbol */
  .highlight .bp {
    color: #E5C07B
  }

  /* Name.Builtin.Pseudo */
  .highlight .fm {
    color: #56B6C2;
    font-weight: bold
  }

  /* Name.Function.Magic */
  .highlight .vc {
    color: #E06C75
  }

  /* Name.Variable.Class */
  .highlight .vg {
    color: #E06C75
  }

  /* Name.Variable.Global */
  .highlight .vi {
    color: #E06C75
  }

  /* Name.Variable.Instance */
  .highlight .vm {
    color: #E06C75
  }

  /* Name.Variable.Magic */
  .highlight .il {
    color: #D19A66
  }
}

/* Literal.Number.Integer.Long */

/* Big languages list */

$lang-list: (
  "abap": "ABAP",
  "abl": "OpenEdge ABL",
  "abnf": "ABNF",
  "aconf": "ApacheConf",
  "actionscript": "ActionScript",
  "actionscript3": "ActionScript 3",
  "ada": "Ada",
  "ada2005": "Ada",
  "ada95": "Ada",
  "adl": "ADL",
  "agda": "Agda",
  "aheui": "Aheui",
  "ahk": "autohotkey",
  "alloy": "Alloy",
  "ambienttalk": "AmbientTalk",
  "amdgpu": "AMDGPU",
  "ampl": "Ampl",
  "ansys": "ANSYS parametric design language",
  "antlr": "ANTLR",
  "antlr-actionscript": "ANTLR With ActionScript Target",
  "antlr-as": "ANTLR With ActionScript Target",
  "antlr-cpp": "ANTLR With CPP Target",
  "antlr-java": "ANTLR With Java Target",
  "antlr-objc": "ANTLR With ObjectiveC Target",
  "antlr-perl": "ANTLR With Perl Target",
  "antlr-python": "ANTLR With Python Target",
  "antlr-rb": "ANTLR With Ruby Target",
  "antlr-ruby": "ANTLR With Ruby Target",
  "apache": "ApacheConf",
  "apacheconf": "ApacheConf",
  "apdl": "ANSYS parametric design language",
  "apl": "APL",
  "applescript": "AppleScript",
  "arduino": "Arduino",
  "arexx": "Rexx",
  "arrow": "Arrow",
  "as": "ActionScript",
  "as3": "ActionScript 3",
  "asc": "ASCII armored",
  "asm": "GAS",
  "aspectj": "AspectJ",
  "aspx-cs": "aspx-cs",
  "aspx-vb": "aspx-vb",
  "asy": "Asymptote",
  "asymptote": "Asymptote",
  "at": "AmbientTalk",
  "augeas": "Augeas",
  "autohotkey": "autohotkey",
  "autoit": "AutoIt",
  "awk": "Awk",
  "b3d": "BlitzBasic",
  "bare": "BARE",
  "basemake": "Base Makefile",
  "bash": "Bash",
  "basic": "QBasic",
  "bat": "Batchfile",
  "batch": "Batchfile",
  "bbcbasic": "BBC Basic",
  "bbcode": "BBCode",
  "bc": "BC",
  "bdd": "Bdd",
  "be": "Berry",
  "befunge": "Befunge",
  "berry": "Berry",
  "bf": "Brainfuck",
  "bib": "BibTeX",
  "bibtex": "BibTeX",
  "blitzbasic": "BlitzBasic",
  "blitzmax": "BlitzMax",
  "bmax": "BlitzMax",
  "bnf": "BNF",
  "boa": "Boa",
  "boo": "Boo",
  "boogie": "Boogie",
  "bplus": "BlitzBasic",
  "brainfuck": "Brainfuck",
  "bro": "Zeek",
  "bsdmake": "Makefile",
  "bst": "BST",
  "bst-pybtex": "BST",
  "bugs": "BUGS",
  "c": "C",
  "c-objdump": "c-objdump",
  "c\\+\\+": "C +  + ",
  "c\\+\\+-objdumb": "cpp-objdump",
  "ca65": "ca65 assembler",
  "cadl": "cADL",
  "camkes": "CAmkES",
  "capdl": "CapDL",
  "capnp": "Cap'n Proto",
  "cbmbas": "CBM BASIC V2",
  "cddl": "CDDL",
  "ceylon": "Ceylon",
  "cf3": "CFEngine3",
  "cfc": "Coldfusion CFC",
  "cfengine3": "CFEngine3",
  "cfg": "INI",
  "cfm": "Coldfusion HTML",
  "cfs": "cfstatement",
  "chai": "ChaiScript",
  "chaiscript": "ChaiScript",
  "chapel": "Chapel",
  "charmci": "Charmci",
  "cheetah": "Cheetah",
  "chpl": "Chapel",
  "cirru": "Cirru",
  "cl": "Common Lisp",
  "clay": "Clay",
  "clean": "Clean",
  "clipper": "FoxPro",
  "clj": "Clojure",
  "cljs": "ClojureScript",
  "clojure": "Clojure",
  "clojurescript": "ClojureScript",
  "cmake": "CMake",
  "cobol": "COBOL",
  "cobolfree": "COBOLFree",
  "coffee": "CoffeeScript",
  "coffee-script": "CoffeeScript",
  "coffeescript": "CoffeeScript",
  "comal": "COMAL-80",
  "comal80": "COMAL-80",
  "common-lisp": "Common Lisp",
  "componentpascal": "Component Pascal",
  "console": "Bash Session",
  "control": "Debian Control file",
  "coq": "Coq",
  "cp": "Component Pascal",
  "cplint": "cplint",
  "cpp": "C +  + ",
  "cpp-objdump": "cpp-objdump",
  "cpsa": "CPSA",
  "cr": "Crystal",
  "crmsh": "Crmsh",
  "croc": "Croc",
  "cry": "Cryptol",
  "cryptol": "Cryptol",
  "crystal": "Crystal",
  "csh": "Tcsh",
  "csound": "Csound Orchestra",
  "csound-csd": "Csound Document",
  "csound-document": "Csound Document",
  "csound-orc": "Csound Orchestra",
  "csound-sco": "Csound Score",
  "csound-score": "Csound Score",
  "css": "CSS",
  "css\\+erb": "CSS + Ruby",
  "css\\+genshi": "CSS + Genshi Text",
  "css\\+genshitext": "CSS + Genshi Text",
  "css\\+lasso": "CSS + Lasso",
  "css\\+mako": "CSS + Mako",
  "css\\+mozpreproc": "CSS + mozpreproc",
  "css\\+myghty": "CSS + Myghty",
  "css\\+php": "CSS + PHP",
  "css\\+ruby": "CSS + Ruby",
  "css\\+smarty": "CSS + Smarty",
  "css\\+ul4": "CSS + UL4",
  "cu": "CUDA",
  "cucumber": "Gherkin",
  "cuda": "CUDA",
  "cxx-objdump": "cpp-objdump",
  "cypher": "Cypher",
  "cython": "Cython",
  "d": "D",
  "d-objdump": "d-objdump",
  "dart": "Dart",
  "dasm16": "DASM16",
  "debcontrol": "Debian Control file",
  "debsources": "Debian Sourcelist",
  "delphi": "Delphi",
  "devicetree": "Devicetree",
  "dg": "dg",
  "diff": "Diff",
  "dmesg": "Kernel log",
  "do": "Stata",
  "docker": "Docker",
  "dockerfile": "Docker",
  "dosbatch": "Batchfile",
  "doscon": "MSDOS Session",
  "dosini": "INI",
  "dot": "Graphviz",
  "dpatch": "Darcs Patch",
  "dtd": "DTD",
  "dts": "Devicetree",
  "duby": "Ruby",
  "duel": "Duel",
  "dylan": "Dylan",
  "dylan-console": "Dylan session",
  "dylan-lid": "DylanLID",
  "dylan-repl": "Dylan session",
  "earl-grey": "Earl Grey",
  "earlgrey": "Earl Grey",
  "easytrieve": "Easytrieve",
  "ebnf": "EBNF",
  "ec": "eC",
  "ecl": "ECL",
  "eg": "Earl Grey",
  "eiffel": "Eiffel",
  "elisp": "EmacsLisp",
  "elixir": "Elixir",
  "elm": "Elm",
  "elpi": "Elpi",
  "emacs": "EmacsLisp",
  "emacs-lisp": "EmacsLisp",
  "email": "E-mail",
  "eml": "E-mail",
  "erb": "ERB",
  "erl": "Erlang erl session",
  "erlang": "Erlang",
  "evoque": "Evoque",
  "ex": "Elixir",
  "execline": "execline",
  "exs": "Elixir",
  "extempore": "xtlang",
  "ezhil": "Ezhil",
  "f90": "Fortran",
  "factor": "Factor",
  "fan": "Fantom",
  "fancy": "Fancy",
  "felix": "Felix",
  "fennel": "Fennel",
  "fish": "Fish",
  "fishshell": "Fish",
  "flatline": "Flatline",
  "flo": "FloScript",
  "floscript": "FloScript",
  "flx": "Felix",
  "fnl": "Fennel",
  "forth": "Forth",
  "fortran": "Fortran",
  "fortranfixed": "FortranFixed",
  "foxpro": "FoxPro",
  "freefem": "Freefem",
  "fstar": "FStar",
  "futhark": "Futhark",
  "fy": "Fancy",
  "gap": "GAP",
  "gas": "GAS",
  "gawk": "Awk",
  "gcode": "g-code",
  "gd": "GDScript",
  "gdscript": "GDScript",
  "genshi": "Genshi",
  "genshitext": "Genshi Text",
  "gherkin": "Gherkin",
  "glsl": "GLSL",
  "gnuplot": "Gnuplot",
  "go": "Go",
  "golang": "Go",
  "golo": "Golo",
  "gooddata-cl": "GoodData-CL",
  "gosu": "Gosu",
  "graphviz": "Graphviz",
  "groff": "Groff",
  "groovy": "Groovy",
  "gsed": "Sed",
  "gsql": "GSQL",
  "gst": "Gosu Template",
  "haml": "Haml",
  "handlebars": "Handlebars",
  "haskell": "Haskell",
  "haxe": "Haxe",
  "haxeml": "Hxml",
  "hexdump": "Hexdump",
  "hlsl": "HLSL",
  "hs": "Haskell",
  "hsa": "HSAIL",
  "hsail": "HSAIL",
  "hspec": "Hspec",
  "html": "HTML",
  "html\\+cheetah": "HTML + Cheetah",
  "html\\+erb": "RHTML",
  "html\\+evoque": "HTML + Evoque",
  "html\\+genshi": "HTML + Genshi",
  "html\\+handlebars": "HTML + Handlebars",
  "html\\+kid": "HTML + Genshi",
  "html\\+lasso": "HTML + Lasso",
  "html\\+mako": "HTML + Mako",
  "html\\+myghty": "HTML + Myghty",
  "html\\+ng2": "HTML  +  Angular2",
  "html\\+php": "HTML + PHP",
  "html\\+ruby": "RHTML",
  "html\\+smarty": "HTML + Smarty",
  "html\\+spitfire": "HTML + Cheetah",
  "html\\+twig": "HTML + Twig",
  "html\\+ul4": "HTML + UL4",
  "html\\+velocity": "HTML + Velocity",
  "htmlcheetah": "HTML + Cheetah",
  "http": "HTTP",
  "hx": "Haxe",
  "hxml": "Hxml",
  "hxsl": "Haxe",
  "hy": "Hybris",
  "hybris": "Hybris",
  "hylang": "Hy",
  "i6": "Inform 6",
  "i6t": "Inform 6 template",
  "i7": "Inform 7",
  "icon": "Icon",
  "idl": "IDL",
  "idl4": "CAmkES",
  "idr": "Idris",
  "idris": "Idris",
  "iex": "Elixir iex session",
  "igor": "Igor",
  "igorpro": "Igor",
  "ik": "Ioke",
  "inform6": "Inform 6",
  "inform7": "Inform 7",
  "ini": "INI",
  "io": "Io",
  "ioke": "Ioke",
  "irb": "Ruby irb session",
  "irc": "IRC logs",
  "isabelle": "Isabelle",
  "j": "J",
  "jade": "Pug",
  "jags": "JAGS",
  "jasmin": "Jasmin",
  "jasminxt": "Jasmin",
  "java": "Java",
  "javascript": "JavaScript",
  "javascript\\+cheetah": "JavaScript + Cheetah",
  "javascript\\+erb": "JavaScript + Ruby",
  "javascript\\+genshi": "JavaScript + Genshi Text",
  "javascript\\+genshitext": "JavaScript + Genshi Text",
  "javascript\\+lasso": "JavaScript + Lasso",
  "javascript\\+mako": "JavaScript + Mako",
  "javascript\\+mozpreproc": "Javascript + mozpreproc",
  "javascript\\+myghty": "JavaScript + Myghty",
  "javascript\\+php": "JavaScript + PHP",
  "javascript\\+ruby": "JavaScript + Ruby",
  "javascript\\+smarty": "JavaScript + Smarty",
  "javascript\\+spitfire": "JavaScript + Cheetah",
  "jbst": "Duel",
  "jcl": "JCL",
  "jl": "Julia",
  "jlcon": "Julia console",
  "jmespath": "JMESPath",
  "jp": "JMESPath",
  "jproperties": "Properties",
  "js": "JavaScript",
  "js\\+cheetah": "JavaScript + Cheetah",
  "js\\+erb": "JavaScript + Ruby",
  "js\\+genshi": "JavaScript + Genshi Text",
  "js\\+genshitext": "JavaScript + Genshi Text",
  "js\\+lasso": "JavaScript + Lasso",
  "js\\+mako": "JavaScript + Mako",
  "js\\+myghty": "JavaScript + Myghty",
  "js\\+php": "JavaScript + PHP",
  "js\\+ruby": "JavaScript + Ruby",
  "js\\+smarty": "JavaScript + Smarty",
  "js\\+spitfire": "JavaScript + Cheetah",
  "js\\+ul4": "Javascript + UL4",
  "jsgf": "JSGF",
  "jslt": "JSLT",
  "json": "JSON",
  "json-ld": "JSON-LD",
  "json-object": "JSON",
  "jsonld": "JSON-LD",
  "jsonml\\+bst": "Duel",
  "jsp": "Java Server Page",
  "julia": "Julia",
  "julia-repl": "Julia console",
  "juttle": "Juttle",
  "k": "K",
  "kal": "Kal",
  "kconfig": "Kconfig",
  "kernel-config": "Kconfig",
  "kid": "Genshi",
  "kmsg": "Kernel log",
  "koka": "Koka",
  "kotlin": "Kotlin",
  "ksh": "Bash",
  "kuin": "Kuin",
  "lagda": "Literate Agda",
  "lasso": "Lasso",
  "lassoscript": "Lasso",
  "latex": "TeX",
  "lcry": "Literate Cryptol",
  "lcryptol": "Literate Cryptol",
  "lean": "Lean",
  "less": "LessCss",
  "lhaskell": "Literate Haskell",
  "lhs": "Literate Haskell",
  "lid": "DylanLID",
  "lidr": "Literate Idris",
  "lidris": "Literate Idris",
  "lighttpd": "Lighttpd configuration file",
  "lighty": "Lighttpd configuration file",
  "lilypond": "LilyPond",
  "limbo": "Limbo",
  "linux-config": "Kconfig",
  "liquid": "liquid",
  "lisp": "Common Lisp",
  "literate-agda": "Literate Agda",
  "literate-cryptol": "Literate Cryptol",
  "literate-haskell": "Literate Haskell",
  "literate-idris": "Literate Idris",
  "live-script": "LiveScript",
  "livescript": "LiveScript",
  "llvm": "LLVM",
  "llvm-mir": "LLVM-MIR",
  "llvm-mir-body": "LLVM-MIR Body",
  "lobas": "VB.net",
  "logos": "Logos",
  "logtalk": "Logtalk",
  "lsl": "LSL",
  "lua": "Lua",
  "m2": "Modula-2",
  "macaulay2": "Macaulay2",
  "macsyma": "Maxima",
  "make": "Makefile",
  "makefile": "Makefile",
  "mako": "Mako",
  "man": "Groff",
  "maql": "MAQL",
  "markdown": "Markdown",
  "mask": "Mask",
  "mason": "Mason",
  "mathematica": "Mathematica",
  "matlab": "Matlab",
  "matlabsession": "Matlab session",
  "mawk": "Awk",
  "maxima": "Maxima",
  "mcf": "MCFunction",
  "mcfunction": "MCFunction",
  "md": "Markdown",
  "menuconfig": "Kconfig",
  "meson": "Meson",
  "meson.build": "Meson",
  "mf": "Makefile",
  "mime": "MIME",
  "minid": "MiniD",
  "miniscript": "MiniScript",
  "mma": "Mathematica",
  "modelica": "Modelica",
  "modula2": "Modula-2",
  "monkey": "Monkey",
  "monte": "Monte",
  "moo": "MOOCode",
  "moocode": "MOOCode",
  "moon": "MoonScript",
  "moonscript": "MoonScript",
  "mosel": "Mosel",
  "mozhashpreproc": "mozhashpreproc",
  "mozpercentpreproc": "mozpercentpreproc",
  "mq4": "MQL",
  "mq5": "MQL",
  "mql": "MQL",
  "mql4": "MQL",
  "mql5": "MQL",
  "ms": "MiniScript",
  "msc": "Mscgen",
  "mscgen": "Mscgen",
  "mupad": "MuPAD",
  "mxml": "MXML",
  "myghty": "Myghty",
  "mysql": "MySQL",
  "nasm": "NASM",
  "nawk": "Awk",
  "nb": "Mathematica",
  "ncl": "NCL",
  "nemerle": "Nemerle",
  "nesc": "nesC",
  "nestedtext": "NestedText",
  "newlisp": "NewLisp",
  "newspeak": "Newspeak",
  "ng2": "Angular2",
  "nginx": "Nginx configuration file",
  "nim": "Nimrod",
  "nimrod": "Nimrod",
  "nit": "Nit",
  "nix": "Nix",
  "nixos": "Nix",
  "nodejsrepl": "Node.js REPL console session",
  "notmuch": "Notmuch",
  "nroff": "Groff",
  "nsh": "NSIS",
  "nsi": "NSIS",
  "nsis": "NSIS",
  "nt": "NestedText",
  "numpy": "NumPy",
  "nusmv": "NuSMV",
  "obj-c": "Objective-C",
  "obj-c\\+\\+": "Objective-C +  + ",
  "obj-j": "Objective-J",
  "objc": "Objective-C",
  "objc\\+\\+": "Objective-C +  + ",
  "objdump": "objdump",
  "objdump-nasm": "objdump-nasm",
  "objective-c": "Objective-C",
  "objective-c\\+\\+": "Objective-C +  + ",
  "objective-j": "Objective-J",
  "objectivec": "Objective-C",
  "objectivec\\+\\+": "Objective-C +  + ",
  "objectivej": "Objective-J",
  "objectpascal": "Delphi",
  "objj": "Objective-J",
  "ocaml": "OCaml",
  "octave": "Octave",
  "odin": "ODIN",
  "omg-idl": "OMG Interface Definition Language",
  "oobas": "VB.net",
  "ooc": "Ooc",
  "opa": "Opa",
  "openbugs": "BUGS",
  "openedge": "OpenEdge ABL",
  "output": "Text output",
  "pacmanconf": "PacmanConf",
  "pan": "Pan",
  "parasail": "ParaSail",
  "pas": "Delphi",
  "pascal": "Delphi",
  "pawn": "Pawn",
  "pcmk": "Crmsh",
  "peg": "PEG",
  "pem": "ASCII armored",
  "perl": "Perl",
  "perl6": "Perl6",
  "php": "PHP",
  "php3": "PHP",
  "php4": "PHP",
  "php5": "PHP",
  "pig": "Pig",
  "pike": "Pike",
  "pkgconfig": "PkgConfig",
  "pl": "Perl",
  "pl6": "Perl6",
  "po": "Gettext Catalog",
  "pointless": "Pointless",
  "pony": "Pony",
  "posh": "PowerShell",
  "postgres": "PostgreSQL SQL dialect",
  "postgres-console": "PostgreSQL console (psql)",
  "postgresql": "PostgreSQL SQL dialect",
  "postgresql-console": "PostgreSQL console (psql)",
  "postscr": "PostScript",
  "postscript": "PostScript",
  "pot": "Gettext Catalog",
  "pov": "POVRay",
  "powershell": "PowerShell",
  "praat": "Praat",
  "procfile": "Procfile",
  "progress": "OpenEdge ABL",
  "prolog": "Prolog",
  "promql": "PromQL",
  "properties": "Properties",
  "proto": "Protocol Buffer",
  "protobuf": "Protocol Buffer",
  "ps1": "PowerShell",
  "ps1con": "PowerShell Session",
  "psm1": "PowerShell",
  "psql": "PostgreSQL console (psql)",
  "psysh": "PsySH console session for PHP",
  "pug": "Pug",
  "puppet": "Puppet",
  "pwsh": "PowerShell",
  "pwsh-session": "PowerShell Session",
  "py": "Python",
  "py2": "Python 2.x",
  "py2tb": "Python 2.x Traceback",
  "py3": "Python",
  "py3tb": "Python Traceback",
  "py\\+ul4": "Python + UL4",
  "pycon": "Python console session",
  "pypy": "PyPy Log",
  "pypylog": "PyPy Log",
  "pyrex": "Cython",
  "pytb": "Python Traceback",
  "python": "Python",
  "python2": "Python 2.x",
  "python3": "Python",
  "pyx": "Cython",
  "q": "Q",
  "qbasic": "QBasic",
  "qbs": "QML",
  "qlik": "Qlik",
  "qlikscript": "Qlik",
  "qliksense": "Qlik",
  "qlikview": "Qlik",
  "qml": "QML",
  "qvt": "QVTO",
  "qvto": "QVTO",
  "r": "S",
  "racket": "Racket",
  "ragel": "Ragel",
  "ragel-c": "Ragel in C Host",
  "ragel-cpp": "Ragel in CPP Host",
  "ragel-d": "Ragel in D Host",
  "ragel-em": "Embedded Ragel",
  "ragel-java": "Ragel in Java Host",
  "ragel-objc": "Ragel in Objective C Host",
  "ragel-rb": "Ragel in Ruby Host",
  "ragel-ruby": "Ragel in Ruby Host",
  "raku": "Perl6",
  "rb": "Ruby",
  "rbcon": "Ruby irb session",
  "rconsole": "RConsole",
  "rd": "Rd",
  "reason": "ReasonML",
  "reasonml": "ReasonML",
  "rebol": "REBOL",
  "red": "Red",
  "redcode": "Redcode",
  "registry": "reg",
  "resource": "ResourceBundle",
  "resourcebundle": "ResourceBundle",
  "rest": "reStructuredText",
  "restructuredtext": "reStructuredText",
  "rexx": "Rexx",
  "rhtml": "RHTML",
  "ride": "Ride",
  "rita": "Rita",
  "rkt": "Racket",
  "rnc": "Relax-NG Compact",
  "rng-compact": "Relax-NG Compact",
  "roboconf-graph": "Roboconf Graph",
  "roboconf-instances": "Roboconf Instances",
  "robotframework": "RobotFramework",
  "rout": "RConsole",
  "rql": "RQL",
  "rs": "Rust",
  "rsl": "RSL",
  "rst": "reStructuredText",
  "rts": "TrafficScript",
  "ruby": "Ruby",
  "rust": "Rust",
  "s": "S",
  "sage": "Python",
  "salt": "YAML + Jinja",
  "sarl": "SARL",
  "sas": "SAS",
  "sass": "Sass",
  "savi": "Savi",
  "sbatch": "Slurm",
  "sc": "SuperCollider",
  "scala": "Scala",
  "scaml": "Scaml",
  "scd": "scdoc",
  "scdoc": "scdoc",
  "scheme": "Scheme",
  "scilab": "Scilab",
  "scm": "Scheme",
  "scss": "SCSS",
  "sed": "Sed",
  "sgf": "SmartGameFormat",
  "sh": "Bash",
  "shell": "Bash",
  "shell-session": "Bash Session",
  "shen": "Shen",
  "shex": "ShExC",
  "shexc": "ShExC",
  "sieve": "Sieve",
  "silver": "Silver",
  "singularity": "Singularity",
  "slash": "Slash",
  "slim": "Slim",
  "sls": "YAML + Jinja",
  "slurm": "Slurm",
  "smali": "Smali",
  "smalltalk": "Smalltalk",
  "smarty": "Smarty",
  "smithy": "Smithy",
  "sml": "Standard ML",
  "snbt": "SNBT",
  "snobol": "Snobol",
  "snowball": "Snowball",
  "sobas": "VB.net",
  "solidity": "Solidity",
  "sophia": "Sophia",
  "sources.list": "Debian Sourcelist",
  "sourceslist": "Debian Sourcelist",
  "sp": "SourcePawn",
  "sparql": "SPARQL",
  "spec": "RPMSpec",
  "spice": "Spice",
  "spicelang": "Spice",
  "spitfire": "Cheetah",
  "splus": "S",
  "sql": "SQL",
  "sql\\+jinja": "SQL + Jinja",
  "sqlite3": "sqlite3con",
  "squeak": "Smalltalk",
  "squid": "SquidConf",
  "squid.conf": "SquidConf",
  "squidconf": "SquidConf",
  "srcinfo": "Srcinfo",
  "ssed": "Sed",
  "ssp": "Scalate Server Page",
  "st": "Smalltalk",
  "stan": "Stan",
  "stata": "Stata",
  "supercollider": "SuperCollider",
  "sv": "systemverilog",
  "swift": "Swift",
  "swig": "SWIG",
  "systemverilog": "systemverilog",
  "t-sql": "Transact-SQL",
  "tads3": "TADS 3",
  "tal": "Tal",
  "tap": "TAP",
  "tasm": "TASM",
  "tcl": "Tcl",
  "tcsh": "Tcsh",
  "tcshcon": "Tcsh Session",
  "tea": "Tea",
  "teal": "teal",
  "teraterm": "Tera Term macro",
  "teratermmacro": "Tera Term macro",
  "termcap": "Termcap",
  "terminfo": "Terminfo",
  "terraform": "Terraform",
  "tex": "TeX",
  "text": "Text only",
  "tf": "Terraform",
  "thingsdb": "ThingsDB",
  "thrift": "Thrift",
  "ti": "ThingsDB",
  "tid": "tiddler",
  "tnt": "Typographic Number Theory",
  "todotxt": "Todotxt",
  "toml": "TOML",
  "trafficscript": "TrafficScript",
  "treetop": "Treetop",
  "ts": "TypeScript",
  "tsql": "Transact-SQL",
  "ttl": "Tera Term macro",
  "turtle": "Turtle",
  "twig": "Twig",
  "typescript": "TypeScript",
  "typoscript": "TypoScript",
  "typoscriptcssdata": "TypoScriptCssData",
  "typoscripthtmldata": "TypoScriptHtmlData",
  "ucode": "ucode",
  "udiff": "Diff",
  "ul4": "UL4",
  "unicon": "Unicon",
  "urbiscript": "UrbiScript",
  "usd": "USD",
  "usda": "USD",
  "uxntal": "Tal",
  "v": "verilog",
  "vala": "Vala",
  "vapi": "Vala",
  "vb.net": "VB.net",
  "vbnet": "VB.net",
  "vbscript": "VBScript",
  "vcl": "VCL",
  "vclsnippet": "VCLSnippets",
  "vclsnippets": "VCLSnippets",
  "vctreestatus": "VCTreeStatus",
  "velocity": "Velocity",
  "verilog": "verilog",
  "vfp": "FoxPro",
  "vgl": "VGL",
  "vhdl": "vhdl",
  "vim": "VimL",
  "wast": "WebAssembly",
  "wat": "WebAssembly",
  "wdiff": "WDiff",
  "webidl": "Web IDL",
  "whiley": "Whiley",
  "winbatch": "Batchfile",
  "winbugs": "BUGS",
  "x10": "X10",
  "xbase": "FoxPro",
  "xml": "XML",
  "xml\\+cheetah": "XML + Cheetah",
  "xml\\+erb": "XML + Ruby",
  "xml\\+evoque": "XML + Evoque",
  "xml\\+genshi": "Genshi",
  "xml\\+kid": "Genshi",
  "xml\\+lasso": "XML + Lasso",
  "xml\\+mako": "XML + Mako",
  "xml\\+myghty": "XML + Myghty",
  "xml\\+php": "XML + PHP",
  "xml\\+ruby": "XML + Ruby",
  "xml\\+smarty": "XML + Smarty",
  "xml\\+spitfire": "XML + Cheetah",
  "xml\\+ul4": "XML + UL4",
  "xml\\+velocity": "XML + Velocity",
  "xorg.conf": "Xorg",
  "xq": "XQuery",
  "xql": "XQuery",
  "xqm": "XQuery",
  "xquery": "XQuery",
  "xqy": "XQuery",
  "xslt": "XSLT",
  "xten": "X10",
  "xtend": "Xtend",
  "xul\\+mozpreproc": "XUL + mozpreproc",
  "yaml": "YAML",
  "yaml\\+jinja": "YAML + Jinja",
  "yang": "YANG",
  "zeek": "Zeek",
  "zephir": "Zephir",
  "zig": "Zig",
  "zsh": "Bash",

);

@each $cls,
$name in $lang-list {
  .lang-#{$cls}:before {
    content: $name;
  }
}
