.form-signin {
  form {
    display: flex;
    flex-direction: column;
    align-items: center;
  }
  .form-floating {
    margin-bottom: 20px;
    width: 100%;
    max-width: 300px;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    >label {
      position: unset;
      padding: 0;
    }
  }
}
