$themes: (
    light: (
        maincolor1: #181818,
        maincolor2: #000,
        color2: #FFFFFF,
        color3: #333333,
        color4: #fff,
        color5: #828282,
        bodybg: #FFFFFF,
        bg1: #181818,
        bgfooter: rgba(255, 255, 255, 0.4),
        bg2: rgba(255, 255, 255, 0.4),
        bg3: #FFFFFF,
        bg4: #E0E0E0,
        bg5: #FAFAFA,
        bg6: #F5F5F5,
        bg7: #009688,
        bg8: #ffffff,
        bg9: #ffffff,
        bg10: #000,
        bg11: #FAFAFA,
        bg12: #F9F9F9,
        bg13: #F9F9F9,
        bg14: #303037,
        bordercolor: #CFCFCF,
        bordercolor2: #009688,
        bordercolor3: rgba(255, 255, 255, 0.4),
        bordercolor4: #EDEDED !important,
        bordercolor5: #E9E9E9,
        bordercolor6: #A58DFF,
        bordercolor7: #31C2B4,
        border1: 1px solid rgba(255, 255, 255, 0.4),
        border2: 1px solid #EDEDED,
        border2_1: 1px solid transparent,
        border3: none,
        border4: 1px solid #CFCFCF,
        bshadow1: 0px 4px 20px rgba(0, 0, 0, 0.15),
        ytb_pth: white,
        svgfill1: #181818,
        hoverbg: #181818e5,
        polz_ic: url(../img/icons/polz_bg.svg),
        bgSlider : rgba(255, 255, 255, 0.4),
        bgDropdown : #FFFFFF,
        hoverbgDropdown : #EDEDED

    ),
    dark: (
        maincolor1: #ccc,
        maincolor2: #ccc,
        color2: #181818,
        color3: #FFFFFF,
        color4: #009688,
        color5: #FFFFFF,
        bodybg: #101010,
        bg1: #FFFFFF,
        bgfooter: rgba(0, 0, 0, 0.4),
        bg2: #222222,
        bg3: #222222,
        bg4: #222222,
        bg5: #171717,
        bg6: #222222,
        bg7: #222222,
        bg8: #171717,
        bg9: #009688,
        bg10: rgb(0, 171, 154),
        bg11: #222222,
        bg12: #0E0E0E,
        bg13: #171717,
        bg14: rgba(255, 255, 255, 0.4),
        bordercolor: #2F2F2F,
        bordercolor2: #2F2F2F,
        bordercolor3: transparent,
        bordercolor4: #2F2F2F !important,
        bordercolor5: #2B2B2B,
        bordercolor6: transparent,
        bordercolor7: transparent,
        border1: none,
        border2: 1px solid #2F2F2F,
        border2_1: 1px solid #2F2F2F,
        border3: 1px solid #2F2F2F,
        border4: 1px solid #585858,
        bshadow1: none,
        ytb_pth: #222222,
        svgfill1: #009688,
        hoverbg: rgb(46, 46, 46),
        polz_ic: url(../img/icons/polz_bg_dark.svg),
        bgSlider : #ffffff,
        bgDropdown : #222222,
        hoverbgDropdown : rgb(46, 46, 46)
    ),
);

/*
 * Implementation of themes
 */
@mixin themify($themes) {

    @each $theme,
    $map in $themes {
        .theme-#{$theme} & {
            $theme-map: () !global;

            @each $key,
            $submap in $map {
                $value: map-get(map-get($themes, $theme), '#{$key}');
                $theme-map: map-merge($theme-map, ($key: $value)) !global;
            }

            @content;
            $theme-map: null !global;
        }
    }
}

@function themed($key) {
    @return map-get($theme-map, $key);
}


// @include themify($themes) {
//     color: themed('maincolor1');
// }
