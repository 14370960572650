@import 'themes.scss';
@import "/var/www/pylot/front/src/scss/base/_mixins.scss";

@import "/var/www/pylot/front/src/scss/pages/article.scss";
@import "/var/www/pylot/front/src/scss/pages/author.scss";
@import "/var/www/pylot/front/src/scss/pages/course-content.scss";
@import "/var/www/pylot/front/src/scss/pages/course-page.scss";
@import "/var/www/pylot/front/src/scss/pages/courses.scss";
@import "/var/www/pylot/front/src/scss/pages/login.scss";
@import "/var/www/pylot/front/src/scss/pages/pay.scss";

@import "/var/www/pylot/front/src/scss/global/_fonts.scss";
@import "/var/www/pylot/front/src/scss/global/_svg.scss";

@import "/var/www/pylot/front/src/scss/_local-fonts.scss";
@import "/var/www/pylot/front/src/scss/base/_mixins.scss";
@import "/var/www/pylot/front/src/scss/global/_fonts.scss";
@import "/var/www/pylot/front/src/scss/global/_svg.scss";

@import 'header.scss';



html,
body {
  height: 100%;
  font-size: 18px;
  font-family: $font-family-sans-serif;
  font-weight: 400;
  overflow-x: hidden;
  line-height: normal;
}

@media print {
  html,
  body {
    height: 100%;
    overflow-y: visible;
    overflow-x: visible;
  }
}

.theme-light {
  background-color: #fff;
}

.theme-dark {
  background-color: #101010;
}


.app-container {
  @include themify($themes) {
    background-color: themed('bodybg');
  }
}

.wrapper {
  display: flex;
  flex-direction: column;
  min-height: 100%;
  position: relative;
}

.wrapper_hidden {
  overflow: hidden;
}

.main {
  flex: 1 1 auto;
  padding: 0px 0px 30px 0px;
}


.theme-light {
  .img_light {
    display: block;
  }

  .img_dark {
    display: none;
  }
}

.theme-dark {
  .img_light {
    display: none;
  }

  .img_dark {
    display: block;
  }
}


.header {
  padding-top: 24px;
  padding-bottom: 24px;
  position: relative;
  z-index: 2;

  &__logo {
    a {
      display: flex;
      gap: 5px;
      align-items: center;

      img, svg {
        max-width: 100%;
      }
    }
  }

  &__sec_02 {
    display: flex;
    align-items: center;
    justify-content: flex-end;
  }
}

.logo_text_svg {
  @include themify($themes) {
    fill: themed('maincolor1');
  }
}

.main_menu {
  min-height: 100%;
  display: flex;
  align-items: center;
  @media print {
    display: none;

  }
  &__list {
    display: flex;
    list-style-type: none;
  }

  &__item {
    margin-right: 30px;

    a {
      font-style: normal;
      font-weight: 700;
      font-size: 20px;
      line-height: 19px;
      // color: #181818;
      @include themify($themes) {
        color: themed('maincolor1');
      }

      &:hover {
        opacity: 0.7;
      }
    }
  }
}

.social_1 {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  list-style-type: none;
  @media print {
    display: none !important;

  }
  &__item {
    a {
      width: 42px;
      height: 42px;
      @include themify($themes) {
        background: themed('bg2');
        border: themed('border1');
        box-shadow: themed('bshadow1');
      }
      border-radius: 10px;
      display: flex;
      align-items: center;
      justify-content: center;
      margin-left: 8px;

      &:hover {
        opacity: 0.8;
      }
    }

  }
}

.ytb_pth {
  @include themify($themes) {
    fill: themed('ytb_pth');
  }
}

.theme-switcher {
  margin-right: 16px;

  &__btn {
    width: 64px;
    height: 35px;
    background: rgba(255, 255, 255, 0.4);
    border: 1px solid rgba(255, 255, 255, 0.4);
    box-shadow: 0px 4px 20px rgba(0, 0, 0, 0.15);
    border-radius: 24px;

    display: flex;
    align-items: center;
    justify-content: space-around;

    position: relative;

    padding-left: 3.5px;
    padding-right: 3px;

    .theme-switcher__light,
    .theme-switcher__dark {
      position: relative;
      z-index: 3;
      cursor: pointer;

      svg {
        width: 17.5px;
        height: 17.5px;

      }
    }

    &::before {
      position: absolute;
      left: 4px;
      z-index: 2;
      display: block;
      content: '';
      width: 27px;
      height: 27px;
      background: #009688;
      border-radius: 100px;
      transition: 0.5s;
    }
  }
}

.theme-switcher__active {
  .theme-switcher__btn {
    background: #222222;
    border: 1px solid #2F2F2F;
    box-shadow: none;
    border-radius: 24px;


    .theme-switcher__light {
      svg {
        path {
          fill: #828282;
        }
      }
    }

    .theme-switcher__dark {
      svg {
        path {
          fill: #fff;
        }
      }
    }

    &::before {
      position: absolute;
      left: 31px;
      z-index: 2;
      display: block;
      content: '';
      width: 27px;
      height: 27px;
      background: #009688;
      border-radius: 100px;
      transition: 0.5s;
    }
  }
}

@media (max-width: 768px) {
  .header {
    padding-top: 15px;
    padding-bottom: 15px;

    &__logo {
      a {

        img {
          max-width: 120px;
        }
      }
    }
  }
  .main_menu {
    min-height: 100%;

    &__item {
      margin-right: 15px;

      a {
        font-size: 20px;
        line-height: 16px;
      }
    }
  }

  .social_1 {
    display: flex;
    align-items: center;
    min-height: 100%;
    justify-content: flex-end;

    &__item {
      a {
        width: 25px;
        height: 25px;
        border-radius: 6px;

        svg {
          max-width: 15px;
        }
      }
    }
  }
  .theme-switcher {
    margin-right: 10px;

    &__btn {
      width: 45px;
      height: 25px;
      padding-left: 2px;
      padding-right: 2px;

      .theme-switcher__light,
      .theme-switcher__dark {
        display: flex;
        align-items: center;

        svg {
          width: 13px;
          height: 13px;
        }
      }

      &::before {
        left: 2px;
        width: 20px;
        height: 20px;
      }
    }
  }
  .theme-switcher__active {
    .theme-switcher__btn {
      &::before {
        left: 22px;
        width: 20px;
        height: 20px;
      }
    }
  }
}

@media (max-width: 576px) {
  .header {
    &__logo {
      display: flex;
      justify-content: center;
      margin-bottom: 15px;
    }
  }
  .theme-switcher {
    position: absolute;
    top: 22px;
    right: 0;
  }
}


.link_1 {
  font-weight: 500;
  font-size: 16px;
  line-height: 19px;
  display: flex;
  align-items: center;
  text-decoration: none;
  @include themify($themes) {
    color: themed('maincolor1');
  }

  &::after {
    position: relative;
    display: block;
    margin-left: 12px;
    margin-top: 3px;
    content: url("data:image/svg+xml,%3Csvg width='20' height='12' viewBox='0 0 20 12' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M19.2667 4.25825L16.0417 0.999917C15.9642 0.92181 15.872 0.859814 15.7705 0.817507C15.6689 0.7752 15.56 0.753418 15.45 0.753418C15.34 0.753418 15.2311 0.7752 15.1295 0.817507C15.028 0.859814 14.9358 0.92181 14.8583 0.999917C14.7031 1.15605 14.616 1.36726 14.616 1.58742C14.616 1.80757 14.7031 2.01878 14.8583 2.17492L17.825 5.16658H0.833333C0.61232 5.16658 0.400358 5.25438 0.244078 5.41066C0.0877974 5.56694 0 5.7789 0 5.99992H0C0 6.22093 0.0877974 6.43289 0.244078 6.58917C0.400358 6.74545 0.61232 6.83325 0.833333 6.83325H17.875L14.8583 9.84158C14.7802 9.91905 14.7182 10.0112 14.6759 10.1128C14.6336 10.2143 14.6118 10.3232 14.6118 10.4333C14.6118 10.5433 14.6336 10.6522 14.6759 10.7537C14.7182 10.8553 14.7802 10.9474 14.8583 11.0249C14.9358 11.103 15.028 11.165 15.1295 11.2073C15.2311 11.2496 15.34 11.2714 15.45 11.2714C15.56 11.2714 15.6689 11.2496 15.7705 11.2073C15.872 11.165 15.9642 11.103 16.0417 11.0249L19.2667 7.79158C19.7348 7.32283 19.9978 6.68742 19.9978 6.02492C19.9978 5.36241 19.7348 4.727 19.2667 4.25825Z' fill='%23009688'/%3E%3C/svg%3E%0A");
  }

  &:hover {
    @include themify($themes) {
      color: themed('maincolor1');
    }

    &::after {
      margin-left: 20px;
    }
  }

}


.title_2 {
  font-style: normal;
  font-weight: 700;
  font-size: 30px;
  line-height: 130%;
  @include themify($themes) {
    color: themed('maincolor1');
  }
}

.title_3 {
  font-style: normal;
  font-weight: 500;
  font-size: 42px;
  line-height: 49px;
  @include themify($themes) {
    color: themed('maincolor1');
  }
}

.title_empty_course {
  @include themify($themes) {
    color: themed('maincolor1');
  }
}

.title_4 {
  font-style: normal;
  font-weight: 700;
  font-size: 34px;
  line-height: 130%;
  @include themify($themes) {
    color: themed('maincolor1');
  }
}


.text_section_01 {
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 150%;
  @include themify($themes) {
    color: themed('maincolor1');
  }
}

.themefy-text-color {
  @include themify($themes) {
    color: themed('maincolor1');
  }
}

@media (max-width: 768px) {
  .text_section_01 {
    font-size: 14px;
  }
}

.text_section_01_invert {
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 150%;
  color: #222222;
}

@media (max-width: 768px) {
  .text_section_01_invert {
    font-size: 14px;
  }
}

.text_section_02 {
  font-style: normal;
  font-weight: 400;
  font-size: 18px;
  line-height: 150%;
  @include themify($themes) {
    color: themed('maincolor1');
  }

  .b {
    font-weight: 700;
  }

  .green {
    color: #009688;
  }

  .title_2 {
    margin-bottom: 20px;
  }
}

@media (max-width: 768px) {
  .text_section_02 {
    font-size: 14px;

    .title_2 {
      margin-bottom: 15px;
    }
  }
}


.text_section_03 {
  margin-bottom: 30px;

  .title_2 {
    margin-bottom: 10px;
  }

  p {
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 150%;
    @include themify($themes) {
      color: themed('maincolor2');
    }
  }
}

@media (max-width: 768px) {
  .text_section_03 {
    margin-bottom: 20px;

    .title_2 {
      margin-bottom: 10px;
    }

    p {
      font-size: 14px;
    }
  }
}


/* ============ */
// Main section
/* ============ */

.figure1 {
  position: absolute;
  left: -250px;
  top: -140px;
  width: 100%;
  height: 100%;
  z-index: -1;

  @media (max-width: 992px) {
    left: -200px;
    top: 100px;
    width: 700px;
    height: 700px;
  }
  // @media (max-width: 768px) {
  //     left: -350px;
  //     top: -250px;
  // }
}

.figure2 {
  position: absolute;
  width: 100%;
  top: -500px;
  right: -200px;
  height: 100%;
  display: flex;
  justify-content: flex-end;

  width: 1400px;
  height: 1400px;

  @media (max-width: 992px) {
    top: -250px;
    right: -300px;
    height: 700px;
    height: 700px;
  }
  // @media (max-width: 768px) {
  //     top: -1000px;
  //     right: -1000px;
  //     img{
  //         height: 400px;
  //         height: 400px;
  //     }
  // }
}


.main_section {
  padding-top: 90px;
  padding-bottom: 50px;
  position: relative;

  .container {
    position: relative;
    z-index: 1;
  }

  &__btn_wrap {
    display: flex;
    align-items: center;

    a {
      text-decoration: none;
    }

  }

  .link_1 {
    margin-left: 25px;
  }

  .main_title {
    margin-bottom: 35px;
  }

  .p1 {
    max-width: 400px;
    margin-bottom: 60px;
  }

  &__image {
    border-radius: 24px;
    overflow: hidden;
    height: 444px;
    display: flex;
    justify-content: center;
    align-items: center;

    img {
      min-height: 100%;
      width: 100%;
      object-fit: cover;
    }
  }

  &__image_mob {
    display: none;
  }

  &__sec1 {
    display: flex;
    flex-flow: column;
    justify-content: center;
  }

  &__sec2 {
    display: flex;
    flex-flow: column;
    justify-content: center;
  }
}

.main_title {
  font-style: normal;
  font-weight: 600;
  font-size: 52px;
  line-height: 130%;
  @include themify($themes) {
    color: themed('maincolor1');
  }
}

.p1 {
  font-style: normal;
  font-weight: 400;
  font-size: 18px;
  line-height: 140%;
  @include themify($themes) {
    color: themed('maincolor1');
  }
}

@media (max-width: 1200px) {
  .main_title {
    font-size: 42px;
  }
}

@media (max-width: 992px) {
  .figure1 {
    right: 200px;
    z-index: -1;
  }
  .main_title {
    font-size: 32px;
  }
  .main_section {
    padding-top: 50px;

    &__btn_wrap {
      flex-flow: column;
      align-items: flex-start;

      .link_1 {
        margin-left: 0;
        margin-top: 30px;
      }
    }

    .p1 {
      font-size: 16px;
      margin-bottom: 40px;
    }
  }
}

@media (max-width: 768px) {
  .main_section {
    padding-top: 40px;
    padding-bottom: 40px;

    .main_title {
      font-size: 28px;
      margin-bottom: 32px;
    }

    &__btn_wrap {
      .link_1 {
        margin-left: 0;
        margin-top: 10px;
      }
    }

    &__image_pc {
      display: none;
    }

    &__image_mob {
      display: flex;
      height: 230px;
      margin-bottom: 30px;
    }

    .p1 {
      font-size: 16px;
      margin-bottom: 30px;
    }
  }
}

@media (max-width: 576px) {
  .figure1 {
    top: -150px;
    z-index: -1;

  }
  .figure2 {
    top: -150px;
  }
}


.howitgo {
  padding-top: 35px;
  padding-bottom: 75px;

  .title_3 {
    margin-bottom: 120px;
  }

  &__item {
    margin-bottom: 30px;
  }

  &__num {
    font-style: normal;
    font-weight: 700;
    font-size: 54px;
    line-height: 65px;
    color: #009688;
    margin-bottom: 16px;
  }

  &__content {
    font-style: normal;
    font-weight: 500;
    font-size: 18px;
    line-height: 21px;
    @include themify($themes) {
      color: themed('maincolor1');
    }
  }

  .howitgo_btn_wrap {
    display: flex;
    justify-content: center;


  }

  &__btn {
    margin: 0 auto;
    text-align: center;
    margin-top: 50px;
  }
}

@media (max-width: 992px) {
  .howitgo {
    padding-top: 35px;
    padding-bottom: 75px;

    .title_3 {
      margin-bottom: 60px !important;
    }

    &__item {
      margin-bottom: 30px;
    }

    &__num {
      font-size: 44px;
      line-height: 55px;
      margin-bottom: 16px;
    }

    &__content {
      font-size: 16px;
      line-height: 19px;
    }

    &__btn {
      margin-top: 40px;
    }
  }
}

@media (max-width: 768px) {
  .howitgo {
    padding-top: 35px;
    padding-bottom: 75px;

    .title_3 {
      margin-bottom: 40px !important;
    }

    &__item {
      margin-bottom: 30px;
    }

    &__num {
      font-size: 34px;
      line-height: 45px;
      margin-bottom: 10px;
    }

    &__content {
      font-size: 14px;
      line-height: 17px;
    }

    &__btn {
      margin-top: 30px;
    }
  }
}

@media (max-width: 576px) {
  .howitgo {
    padding-top: 35px;
    padding-bottom: 35px;

    &__item {
      margin-bottom: 30px;
      display: flex;
      justify-content: flex-start;
    }

    &__num {
      font-size: 34px;
      line-height: 45px;
      margin-bottom: 10px;
      margin-top: -10px;
      margin-right: 20px;
    }

    &__btn {
      margin-top: 20px;
      padding: 12px 23px;
    }
  }
}


.text_sec_1_5 {
  padding-top: 25px;
  padding-bottom: 40px;

  .title_3 {
    margin-bottom: 34px;
  }

  p {
    margin-bottom: 2.5rem;
  }

  &__sec1 {
    display: flex;
    flex-flow: column;
    justify-content: center;
    min-height: 100%;
  }

  &__content {
    font-weight: 400;
    font-size: 16px;
    line-height: 150%;
    @include themify($themes) {
      color: themed('maincolor1');
    }
  }

  &__sec2 {
    display: flex;
    justify-content: flex-end;
  }

  &__image {
    img {
      width: 100%;
    }
  }

  &__video {
    overflow: hidden;
    border-radius: 24px;
    position: relative;
    width: 637px;
    height: 359px;

    iframe {
      width: 637px;
      height: 359px;
    }

    .v_poster {
      // position: relative;
      top: 0;
      left: 0;
      display: flex;
      align-items: center;
      justify-content: center;
      overflow: hidden;

      &::after {
        position: absolute;
        content: '';
        display: block;
        width: 100%;
        height: 100%;
        left: 0;
        top: 0;
        right: 0;
        bottom: 0;
        background: linear-gradient(0deg, rgba(0, 0, 0, 0.3), rgba(0, 0, 0, 0.3));
        box-shadow: 0px 4px 20px rgba(0, 0, 0, 0.05);
      }

      img {
        display: flex;
        justify-content: center;
        text-align: center;
        height: 100%;
        position: absolute;
        top: 0;
        left: 0;
      }

      .viedeo_play {
        position: absolute;
        z-index: 2;
        left: 0;
        right: 0;
        margin: 0 auto;
        display: flex;
        width: 100px;
        height: 100px;
        top: calc(50% - 100px / 2);
        cursor: pointer;
        transition: 0.3s;

        &:hover {
          transform: scale(1.05);
        }
      }
    }

    @media (max-width: 1200px) {
      width: 637px;
      height: 310px;
      iframe {
        width: 637px;
        height: 310px;
      }
    }
    @media (max-width: 992px) {
      width: 550px;
      height: 310px;
      iframe {
        width: 550px;
        height: 310px;
      }
    }
    @media (max-width: 768px) {
      width: 490px;
      height: 280px;
      border-radius: 12px;
      iframe {
        width: 490px;
        height: 280px;
      }
    }
    @media (max-width: 576px) {
      width: 300px;
      height: 180px;
      iframe {
        width: 300px;
        height: 180px;
      }
      .viedeo_play {
        width: 70px !important;
        height: 70px !important;
        top: calc(50% - 70px / 2) !important;
      }
    }
  }
}


@media (max-width: 992px) {
  .text_sec_1_5 {
    padding-top: 35px;
    padding-bottom: 40px;

    &__image {
      margin-top: 30px;
    }
  }
}

@media (max-width: 768px) {

  .text_sec_1_5 {
    padding-top: 35px;
    padding-bottom: 20px;

    &__content {
      font-size: 14px;
    }
  }
}


.rubrics_page {
  .post_min__bottom {
    flex: 50 auto;
  }
}

@media (max-width: 1200px) {
  .rubrics_page {
    .p_m_l_2 {
      font-size: 12px;
      left: 10px;
      bottom: 10px;
    }

    .courses_i {
      align-items: flex-start;
    }
  }
}

@media (max-width: 992px) {
  .rubrics_page {
    padding-bottom: 80px;

    .row_w_sidebar__content {
      padding-bottom: 20px;
    }

    .row_w_sidebar__sidbar {
      padding-top: 20px;
    }

    .courses_i {
      display: flex;
      flex-flow: row;
      align-items: center;

      &__image {
        margin-right: 30px;
      }

      .courses_i__text {
        font-size: 14px;
      }

      .courses_i__time {
        font-size: 14px;
      }
    }

    .subscribe_1__social {
      justify-content: flex-start;

    }
  }
}

@media (max-width: 768px) {
  .rubrics_page {
    .title_2 {
      margin-bottom: 20px !important;
    }

    .p_m_l_2 {
      font-size: 10px;
      left: 10px;
    }
  }
}

@media (max-width: 576px) {
  .rubrics_page {
    padding-bottom: 50px;

    .courses_i {
      display: flex;
      flex-flow: column;
      align-items: center;
      padding: 15px;

      &__image {
        margin-right: 0px;
      }
    }

    .subscribe_1 {
      padding: 15px;

      .subscribe_1__title {
        font-size: 16px;
      }

      .subscribe_1__form {
        flex-flow: column;

        .input1 {
          margin-bottom: 10px;
        }

        .btn_04 {
          width: 100%;
        }
      }
    }
  }
}


.courses {
  position: relative;
  z-index: 2;
  padding-top: 50px;
  padding-bottom: 35px;

  .title_3 {
    margin-bottom: 60px;
  }
}


.courses_i {
  display: flex;
  width: 100%;
  border-radius: 24px;
  padding: 40px 38px 38px 27px;
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;

  &__bgc_1 {
    background-image: url(/static/img/bg_grad_1.webp);
  }

  &__bgc_2 {
    background-image: url(/static/img/bg_grad_2.webp);
  }

  &__bgc_3 {
    background-image: url(/static/img/bg_grad_3.webp);
  }

  &__bgc_4 {
    background-image: url(/static/img/bg_grad_4.webp);
  }

  &__bgc_5 {
    background-image: url(/static/img/bg_grad_5.webp);
  }

  &__image {
    img {
      width: 192px;
    }
  }

  &__content {
    margin-left: 36px;
  }

  &__name {
    font-style: normal;
    font-weight: 700;
    font-size: 24px;
    line-height: 29px;
    @include themify($themes) {
      color: themed('maincolor1');
    }
    margin-bottom: 10px;
  }

  &__text {
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 16px;
    margin-bottom: 13px;
    @include themify($themes) {
      color: themed('maincolor1');
    }
  }

  &__time {
    display: flex;
    align-items: center;
    font-weight: 400;
    font-size: 16px;
    line-height: 19px;
    margin-bottom: 20px;
    @include themify($themes) {
      color: themed('maincolor1');
    }

    svg {
      margin-right: 8px;

      path {
        @include themify($themes) {
          fill: themed('maincolor1');
        }
      }
    }

    span {
      display: flex;
      align-items: center;
    }
  }

  .linck_more {
    margin-left: 20px;
    @include themify($themes) {
      color: themed('maincolor1');
    }
  }
}


@media (max-width: 1400px) {
  .courses_i {
    .linck_more {
      display: inline-block;
      margin-left: 0;
      margin-top: 20px;
    }
  }
}

@media (max-width: 1200px) {
  .courses_i {
    align-items: center;
    padding: 30px 30px 30px 22px;

    &__image {
      img {
        width: 192px;
      }
    }
  }
}

@media (max-width: 992px) {
  .courses_i {
    align-items: flex-start;
    flex-flow: column;
    padding: 30px 24px;

    &__content {
      margin-top: 27px;
      margin-left: 0;
    }

    &__name {
      font-size: 20px;
      line-height: 25px;
      margin-bottom: 10px;
    }

    .linck_more {
      margin-left: 15px;
    }
  }
}

@media (max-width: 768px) {
  .courses {
    padding-top: 25px;
  }
  .courses_i {
    align-items: flex-start;
    flex-flow: column;
    padding: 15px 15px;

    &__content {
      margin-top: 15px;
      margin-left: 0;
    }

    &__image {
      img {
        max-width: 192px;
      }
    }

    &__name {
      font-size: 20px;
      line-height: 25px;
      margin-bottom: 10px;
    }

    .linck_more {
      margin-left: 15px;
    }
  }
}

@media (max-width: 576px) {
  .courses_i {
    align-items: flex-start;
    flex-flow: column;
    padding: 15px 15px;
    margin-bottom: 20px;

    &__content {
      margin-top: 15px;
      margin-left: 0;
    }

    &__image {
      img {
        max-width: 192px;
      }
    }

    &__name {
      font-size: 20px;
      line-height: 25px;
      margin-bottom: 10px;
    }

    &__time {
      font-size: 14px;
      line-height: 16px;
    }

    &__btns {
      display: flex;
      flex-flow: column;
    }

    .linck_more {
      margin-top: 10px;
      margin-left: 0px;
    }
  }
}


.courses_i_vert {
  flex-flow: column;
  padding: 30px 24px;

  .courses_i__content {
    margin-left: 0;
    margin-top: 27px;
  }

  .courses_i__text {
    font-size: 16px;
    margin-bottom: 20px;
  }
}


.courses_page {
  padding-bottom: 80px;

  .row {
    margin-bottom: 30px;
  }

  &__row {
    margin-bottom: 40px;
  }
}

@media (max-width: 768px) {
  .courses_page {
    padding-bottom: 50px;

    &__row {
      margin-bottom: 20px;
    }
  }
}


.btn_green {
  font-style: normal;
  font-weight: 700;
  font-size: 16px;
  line-height: 19px;
  color: #FFFFFF;
  display: flex;
  align-items: center;
  justify-content: center;
  background: #009688;
  border-radius: 10px;
  padding: 17px 30px;
  text-decoration: none;

  &:hover {
    color: #FFFFFF;
    background: rgba(0, 150, 135, 0.8)
  }
}

.link_1 {
  font-weight: 500;
  font-size: 16px;
  line-height: 19px;
  color: #181818;
  display: flex;
  align-items: center;

  &::after {
    position: relative;
    display: block;
    margin-left: 12px;
    margin-top: 3px;
    content: url("data:image/svg+xml,%3Csvg width='20' height='12' viewBox='0 0 20 12' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M19.2667 4.25825L16.0417 0.999917C15.9642 0.92181 15.872 0.859814 15.7705 0.817507C15.6689 0.7752 15.56 0.753418 15.45 0.753418C15.34 0.753418 15.2311 0.7752 15.1295 0.817507C15.028 0.859814 14.9358 0.92181 14.8583 0.999917C14.7031 1.15605 14.616 1.36726 14.616 1.58742C14.616 1.80757 14.7031 2.01878 14.8583 2.17492L17.825 5.16658H0.833333C0.61232 5.16658 0.400358 5.25438 0.244078 5.41066C0.0877974 5.56694 0 5.7789 0 5.99992H0C0 6.22093 0.0877974 6.43289 0.244078 6.58917C0.400358 6.74545 0.61232 6.83325 0.833333 6.83325H17.875L14.8583 9.84158C14.7802 9.91905 14.7182 10.0112 14.6759 10.1128C14.6336 10.2143 14.6118 10.3232 14.6118 10.4333C14.6118 10.5433 14.6336 10.6522 14.6759 10.7537C14.7182 10.8553 14.7802 10.9474 14.8583 11.0249C14.9358 11.103 15.028 11.165 15.1295 11.2073C15.2311 11.2496 15.34 11.2714 15.45 11.2714C15.56 11.2714 15.6689 11.2496 15.7705 11.2073C15.872 11.165 15.9642 11.103 16.0417 11.0249L19.2667 7.79158C19.7348 7.32283 19.9978 6.68742 19.9978 6.02492C19.9978 5.36241 19.7348 4.727 19.2667 4.25825Z' fill='%23009688'/%3E%3C/svg%3E%0A");
  }

  &:hover {
    color: #181818a9;

    &::after {
      margin-left: 20px;
    }
  }
}


.btn_black {
  font-weight: 700;
  font-size: 14px;
  line-height: 17px;
  text-decoration: none;
  @include themify($themes) {
    color: themed('color2');
  }
  padding: 12px 25px;
  @include themify($themes) {
    background-color: themed('bg1');
  }
  border-radius: 10px;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  border: none;
  margin-right: 20px;

  &:hover {
    color: #fff;
    background: #181818e5;
  }
}

.linck_more {
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 16px;
  color: #181818;
  text-decoration: none;

  &:hover {
    text-decoration: underline;
    color: #181818;
  }
}


.title_2 {
  font-style: normal;
  font-weight: 700;
  font-size: 30px;
  line-height: 130%;
  @include themify($themes) {
    color: themed('maincolor1');
  }
}

@media (max-width: 768px) {
  .title_2 {
    font-size: 24px;
  }
}

.title_3 {
  font-style: normal;
  font-weight: 500;
  font-size: 42px;
  line-height: 49px;
  @include themify($themes) {
    color: themed('maincolor1');
  }
}

.title_4 {
  font-style: normal;
  font-weight: 700;
  font-size: 34px;
  line-height: 130%;
  @include themify($themes) {
    color: themed('maincolor1');
  }
}


@media (max-width: 992px) {
  .title_3 {
    font-size: 34px;
    line-height: 42px;
    margin-bottom: 40px !important;
  }
  .title_4 {
    font-size: 30px;
  }
}

@media (max-width: 768px) {
  .title_3 {
    font-size: 26px;
    line-height: 32px;
    margin-bottom: 20px !important;
  }
  .title_4 {
    font-size: 26px;
    line-height: 120%;
  }
}


.breadcrumb {
  margin-top: 20px;
  margin-bottom: 30px;
  @media print {
    display: none;
  }
  .breadcrumb-item {
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 16px;
    color: #828282;

    a {
      font-style: normal;
      font-weight: 400;
      font-size: 14px;
      line-height: 16px;
      color: #828282;
      text-decoration: none;

      &:hover {
        color: #828282;
        text-decoration: underline;
      }
    }
  }
}

@media (max-width: 768px) {
  .breadcrumb {
    margin-top: 20px;
    margin-bottom: 15px;
  }
}


.pagination {
  --bs-pagination-border-radius: 10px;

  &__wrapp {
    display: flex;
    justify-content: center;
    margin-top: 30px;
    margin-bottom: 30px;
  }

  .page-item {
    &__prev {
      .page-link {
        width: 100%;
        padding-left: 20px;
        padding-right: 20px;
        font-style: normal;
        font-weight: 700;
        font-size: 16px;
        line-height: 19px;
        @include themify($themes) {
          color: themed('color3');
        }
      }
    }

    &__next {
      .page-link {
        width: 100%;
        padding-left: 20px;
        padding-right: 20px;
        font-style: normal;
        font-weight: 700;
        font-size: 16px;
        line-height: 19px;
        @include themify($themes) {
          color: themed('color3');
        }
      }
    }
  }

  .page-link {
    padding: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 50px;
    height: 50px;
    font-family: 'Ubuntu', sans-serif;
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 18px;
    text-align: center;
    @include themify($themes) {
      color: themed('maincolor1');
      border-color: themed('bordercolor');
      background-color: themed('bg3');
    }

    &:focus {
      box-shadow: none;
      // background-color: #fff;
      @include themify($themes) {
        background-color: themed('bg3');
      }
    }

    &:hover {
      background: #0096870d;
    }
  }

  .active {
    .page-link {
      border: none;
      border: 1px solid;
      @include themify($themes) {
        background-color: themed('bg7');
        border-color: themed('bordercolor2');
        color: themed('color4');
      }
    }
  }
}

@media (max-width: 768px) {
  .pagination {

    .page-link {
      width: 35px;
      height: 35px;
      font-size: 14px;
      line-height: 17px;
    }

    .page-item {
      &__prev {
        .page-link {
          padding-left: 10px;
          padding-right: 10px;
          font-size: 13px;
          line-height: 17px;
        }
      }

      &__next {
        .page-link {
          padding-left: 10px;
          padding-right: 10px;
          font-size: 13px;
          line-height: 17px;
        }
      }
    }
  }
}


.label1 {
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 150%;
  color: #828282;
  margin-bottom: 10px;
}

.input1 {
  display: block;
  height: 45px;
  width: 100%;
  // background: #FFFFFF;
  @include themify($themes) {
    background: themed('bg3');
    border: themed('border2');
    color: themed('maincolor1');
  }
  // border: 1px solid #EDEDED;
  border-radius: 10px;
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 150%;
  padding-left: 14px;
  margin-bottom: 14px;

  &::placeholder {
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 150%;
    color: #333333;
  }
}

.textarea1 {
  display: block;
  height: 108px;
  width: 100%;
  @include themify($themes) {
    background: themed('bg3');
    border: themed('border2');
    color: themed('maincolor1');
  }
  // border: 1px solid #EDEDED;
  border-radius: 10px;
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 150%;
  resize: none;
  padding-left: 14px;
}


.subscribe_1 {
  border: 1px solid;
  box-shadow: 0px 4px 20px rgba(0, 0, 0, 0.10);
  backdrop-filter: blur(20px);
  border-radius: 24px;
  padding: 24px;
  margin: 20px 0px 20px 0px;
  @include themify($themes) {
    background: themed('bg8');
    border-color: themed('bordercolor3');
  }

  &__title {
    font-style: normal;
    font-weight: 700;
    font-size: 18px;
    line-height: 130%;
    @include themify($themes) {
      color: themed('color3');
    }
  }

  &__form {
    margin-top: 20px;
    margin-bottom: 11px;
    display: flex;
    align-items: center;

    .input1 {
      margin-bottom: 0;
      margin-right: 7px;
      border: 1px solid;
      box-sizing: border-box;
      @include themify($themes) {
        border-color: themed('bordercolor');
      }

      &::placeholder {
        @include themify($themes) {
          color: themed('color3');
        }
      }
    }

    .btn_04 {
      @include themify($themes) {
        background: themed('bg9');
        color: themed('maincolor1');
        &:hover {
          background-color: themed('bg10');
          color: #fff;
        }
      }
    }
  }

  &__social {
    display: flex;
    align-items: center;
    justify-content: space-between;

    span {
      font-weight: 400;
      font-size: 16px;
      line-height: 150%;
      @include themify($themes) {
        color: themed('color3');
      }
    }

    ul {
      display: flex;
      align-items: center;

      li {
        margin-left: 14px;

        a {
          &:hover {
            opacity: 0.8;
          }
        }
      }
    }
  }
}

.btn_04 {
  font-style: normal;
  font-weight: 700;
  font-size: 14px;
  line-height: 150%;
  color: #181818;
  padding: 11px 18px;
  border: 1px solid #181818;
  border-radius: 9px;
  background-color: #fff;

  &:hover {
    background-color: #000;
    color: #fff;

  }
}


.input2 {
  font-family: 'Ubuntu', sans-serif;
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 150%;
  color: #F2F2F2;
  border: 1px solid #E0E0E0;
  border-radius: 10px;
  background-color: transparent;
  padding: 16px 17px;

  &::placeholder {
    font-family: 'Ubuntu', sans-serif;
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 150%;
    color: #F2F2F2;
  }
}

.form_h_send {
  border: none;
  width: 100%;
  height: 52px;
}

.form_help {
  background: #009688;
  border-radius: 24px;
  padding: 42px 70px 32px 40px;
  display: flex;
  justify-content: space-between;

  &__title {
    font-family: 'Ubuntu', sans-serif;
    font-style: normal;
    font-weight: 700;
    font-size: 32px;
    line-height: 130%;
    color: #FFFFFF;
    margin-bottom: 20px;
  }

  &__form {
    display: flex;
    flex-wrap: wrap;

    .input2 {
      width: 100%;
      height: 52px;
    }

    .i_wrp {
      width: 50%;
      padding-bottom: 10px;
      padding-left: 14px;
    }
  }

  &__sec1 {
    font-family: 'Ubuntu', sans-serif;
    font-style: normal;
    font-weight: 400;
    font-size: 18px;
    line-height: 150%;
    color: #FFFFFF;
    max-width: 372px;
  }

  &__sec2 {
    max-width: 572px;
  }

  &__agree {
    font-family: 'Ubuntu', sans-serif;
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 150%;
    color: #FFFFFF;
    padding-left: 14px;

    a {
      color: #F2C94C;
      text-decoration: underline;

      &:hover {
        color: #F2C94C;
        text-decoration: none;
      }
    }
  }
}

@media (max-width: 992px) {
  .form_help {
    flex-flow: column;
    padding: 42px 40px 32px 40px;

    &__title {
      font-size: 28px;
      margin-bottom: 20px;
    }

    &__sec1 {
      width: 100%;
      font-size: 16px;
    }

    &__sec2 {
      max-width: 100%;
      margin-top: 20px;
    }

    &__agree {
      padding-left: 0;
    }

    .i_wrp {
      padding-left: 0;
      padding-right: 14px;
    }
  }
}

@media (max-width: 768px) {
  .form_help {
    flex-flow: column;
    padding: 32px 20px 32px 20px;

    &__title {
      font-size: 24px;
      margin-bottom: 20px;
    }

    &__form {
      .input2 {
        height: 42px;
        padding: 10px;
      }

      .i_wrp {
        width: 50%;
        padding-bottom: 10px;
      }

      .form_h_send {
        padding: 10px;
        height: 42px;
      }
    }
  }
}

@media (max-width: 576px) {
  .form_help {
    padding: 20px 15px 20px 15px;

    &__title {
      font-size: 24px;
      margin-bottom: 20px;
    }

    &__form {
      .input2 {
        height: 42px;
        padding: 10px;
      }

      .i_wrp {
        width: 100%;
        padding-right: 0;
      }
    }
  }
}


.posts_section {
  padding-top: 35px;
  padding-bottom: 15px;

  .title_3 {
    margin-bottom: 60px;
  }
}

.post_min {
  display: flex;
  flex-direction: column;
  height: 100%;
  padding-bottom: 30px;

  &__top {
    position: relative;
  }

  &__main {
    flex: 1 1 auto;

    a {
      text-decoration: unset;
    }
  }

  &__image {
    display: inline-flex;
    border-radius: 24px;
    overflow: hidden;
    display: flex;
    align-items: center;
    justify-content: center;
    // width: 100%;
    // height: 238px;
    // @media (max-width: 1200px) {
    //     height: 210px;
    // }
    // @media (max-width: 768px) {
    //     height: 180px;
    //     border-radius: 18px;
    // }
    // @media (max-width: 576px) {
    //     height: 220px;
    // }
    // @media (max-width: 400px) {
    //     height: 180px;
    // }
    // img{
    //     object-fit: cover;
    //     width: 100%;
    //     height: 100%;
    //     // min-width: 100%;
    // }
    width: 420px;
    height: 236px;

    img {
      border-radius: 24px;
      width: 420px;
      height: 236px;
    }

    @media (max-width: 1400px) {
      width: 360px;
      height: 202px;
      img {
        width: 360px;
        height: 202px;
      }
    }
    @media (max-width: 1200px) {
      width: 299px;
      height: 169px;
      img {
        width: 299px;
        height: 169px;
      }
    }
    @media (max-width: 992px) {
      width: 339px;
      height: 190px;
      img {
        width: 339px;
        height: 190px;
      }
    }
    @media (max-width: 768px) {
      width: 249px;
      height: 140px;
      border-radius: 18px;
      img {
        width: 249px;
        height: 140px;
        border-radius: 18px;
      }
    }
    @media (max-width: 576px) {
      width: 100%;
      height: 100%;
      min-width: 100%;
      min-height: 168px;
      max-width: 554px;
      max-height: 311px;
      img {
        width: 100%;
        height: 100%;
        max-width: 554px;
        max-height: 311px;
      }
    }
  }

  &__label {
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 16px;
    padding: 6px 14px;
    display: inline-block;
    @include themify($themes) {
      background: themed('bg3');
      color: themed('maincolor1');
    }
    box-shadow: 0px 4px 20px rgba(0, 0, 0, 0.05);
    border-radius: 24px;
    position: absolute;
    bottom: 18px;
    left: 18px;

    &:hover {
      color: #009688;
    }

    @media (max-width: 768px) {
      bottom: 10px;
      left: 10px;
    }
  }

  &__level {
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 19px;
    margin-top: 16px;
  }

  .beginner {
    color: #009688;
  }

  .advanced {
    color: #8648CF;
  }

  .hard {
    color: #FF1A1A;
  }

  &__name {
    margin-top: 10px;
    margin-bottom: 14px;
    font-style: normal;
    font-weight: 700;
    font-size: 24px;
    line-height: 29px;
    @include themify($themes) {
      color: themed('maincolor1');
    }
    width: 100%;

    &:hover {
      opacity: 0.6;
    }
  }

  &__preview {
    font-weight: 400;
    font-size: 16px;
    line-height: 19px;
    @include themify($themes) {
      color: themed('maincolor1');
    }
  }

  &__date {
    display: flex;
    align-items: center;
    margin-top: 15px;
    @include themify($themes) {
      color: themed('color5');
    }
    font-size: 14px;

    svg {
      margin-right: 12px;

      path {
        @include themify($themes) {
          fill: themed('maincolor1');
        }
      }
    }
  }
}

.p_m_l_2 {
  background: rgba(255, 255, 255, 0.4);
  border: 1px solid;
  @include themify($themes) {
    border-color: themed('bordercolor3');
  }
  box-shadow: 0px 4px 20px rgba(0, 0, 0, 0.05);
  backdrop-filter: blur(20px);
  border-radius: 24px;
  font-weight: 700;
}

.p_m_l_3 {
  background: linear-gradient(93.44deg, #A58DFF -6.9%, #31C2B4 108.66%);
  box-shadow: 0px 4px 20px rgba(0, 0, 0, 0.05);
  backdrop-filter: blur(20px);
  border-radius: 24px;
  font-weight: 700;

  &:hover {
    color: #181818b3;
  }
}

.to_all_posts_1 {
  margin: 0 auto;
  display: flex;
  text-align: center;
  justify-content: center;
  margin-top: 59px;
  font-weight: 400;
}

.to_all_posts_2 {
  display: inline-flex;
  align-items: center;
  justify-content: center;
  padding: 12px 25px;
  @include themify($themes) {
    background: themed('maincolor1');
    color: themed('color2');
    &:hover {
      color: #fff;
      background: themed('hoverbg');
    }
  }
  margin: 0 auto;
  text-align: center;
  border-radius: 10px;
}


@media (max-width: 992px) {
  .post_min {
    &__name {
      font-size: 22px;
      line-height: 26px;
    }
  }
}

@media (max-width: 768px) {
  .posts_section {
    .to_all_posts_1 {
      margin-top: 20px;
    }
  }
  .post_min {
    &__level {
      font-size: 14px;
      margin-top: 12px;
    }

    &__label {
      font-size: 10px;
    }

    &__name {
      font-size: 18px;
      line-height: 22px;
    }

    &__preview {
      font-size: 14px;
      line-height: 17px;
    }

    &__date {
      margin-top: 10px;
    }
  }
}

@media (max-width: 576px) {
  .post_min {
    &__label {
      padding: 4px 10px;
      font-size: 12px;
    }
  }
}


.post_feed_list {
  .post_min__date {
    color: #828282;
    margin-top: 0px;
  }

  .post_min__main {
    flex: auto !important;

  }

  .post_min__bottom {
    flex: 50 auto;
    margin-top: 12px;
    align-items: flex-start !important;
  }
}


.main-blog {
  .title_2 {
    margin-bottom: 30px;
  }
}

@media (max-width: 768px) {
  .main-blog {
    padding-top: 20px;
  }
}


.row_w_sidebar {
  .title_2 {
    margin-bottom: 30px;
  }

  &__content {
    padding-bottom: 50px;

    .row {
      margin-bottom: 10px;
    }
  }

}


.reviews {
  padding-top: 40px;
  padding-bottom: 55px;
  @include themify($themes) {
    color: themed('maincolor1');
  }

  .title_3 {
    margin-bottom: 80px;
  }

  &__avatar {
    width: 68px;
    height: 68px;
    border-radius: 50%;
    overflow: hidden;

    img {
      width: 100%;
    }
  }

  &__name {
    font-style: normal;
    font-weight: 700;
    font-size: 22px;
    line-height: 27px;
    margin-top: 14px;
    margin-bottom: 12px;
  }

  &__text {
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 130%;
    margin-top: 16px;
  }

  .row {
    position: relative;
  }

}

.slider_btn {
  width: 50px;
  height: 50px;
  background: #009688;
  border-radius: 10px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  transition: 0.4s;
  position: absolute;
  top: calc(50% - 50px);

  &:hover {
    opacity: 0.85;
  }
}

.slider_reviews_prev {
  left: -87px;
}

.slider_reviews_next {
  right: -87px;
}

.pagination-slider {
  display: flex;
  justify-content: center;
  gap: 10px;
  margin-top: 80px;

  .swiper-pagination-bullet {
    width: 36px;
    height: 6px;
    @include themify($themes) {
      background: themed('bg4');
    }
    border-radius: 10px;
    opacity: 1;
  }

  .swiper-pagination-bullet-active {
    background: #009688 !important;
  }
}


@media (max-width: 1520px) {
  .reviews {
    .row {
      padding-left: 70px;
      padding-right: 70px;
    }

    .slider_reviews_prev {
      left: 10px;
    }

    .slider_reviews_next {
      right: 10px;
    }
  }
}

@media (max-width: 992px) {
  .reviews {
    .row {
      padding-left: 45px;
      padding-right: 45px;
    }

    &__name {
      font-size: 20px;
      line-height: 25px;
    }

    .slider_reviews_prev {
      left: 10px;
    }

    .slider_reviews_next {
      right: 10px;
    }

    .slider_btn {
      width: 35px;
      height: 35px;

      svg {
        max-height: 18px;
      }
    }
  }
  .pagination-slider {
    margin-top: 60px;

    .swiper-pagination-bullet {
      width: 28px;
      height: 6px;
    }
  }
}

@media (max-width: 768px) {
  .reviews {
    .row {
      padding-left: 0px;
      padding-right: 0px;
    }

    .title_3 {
      margin: 0 auto;
      text-align: center;
    }

    &__avatar {
      width: 60px;
      height: 60px;
    }

    &__name {
      font-size: 18px;
      line-height: 23px;
    }

    .slider_btn {
      display: none;
    }

    .swiper-slide {
      display: flex;
      flex-flow: column;
      align-items: center;
    }
  }
  .rating_star {
    svg {
      max-width: 18px;
    }
  }
  .pagination-slider {
    margin-top: 30px;

    .swiper-pagination-bullet {
      width: 28px;
      height: 6px;
    }
  }
}

@media (max-width: 576px) {
  .pagination-slider {
    .swiper-pagination-bullet {
      width: 18px;
      height: 6px;
    }
  }
}


.footer {
  @media print {
    display: none;

  }
  @include themify($themes) {
    background: themed('bgfooter');
    color: themed('maincolor1');
  }
  box-shadow: 0px 4px 20px rgba(0, 0, 0, 0.05);
  padding-top: 40px;
  padding-bottom: 40px;
  font-weight: 400;
  font-size: 16px;
  line-height: 130%;

  p {
    font-weight: 400;
    font-size: 16px;
    line-height: 130%;
  }

  &__logo {
    margin-bottom: 13px;
    display: flex;
    gap: 5px;
    align-items: center;

    img {
      max-width: 137px;
      margin-left: -5px;
    }
  }

  .pays_p {
    margin-top: 29px;
    display: flex;
    align-items: center;

    a {
      display: inline-block;
      margin-right: 24px;
    }
  }

  &__social {
    display: flex;
    margin-top: 12px;
    list-style-type: none;

    li {
      margin-left: 10px;
    }

    a {
      @include themify($themes) {
        background: themed('bg3');
      }
      border-radius: 10px;
      width: 34px;
      height: 34px;
      display: flex;
      align-items: center;
      justify-content: center;

      svg {
        max-width: 21px;
        max-height: 21px;

        path:first-child {
          @include themify($themes) {
            fill: themed('svgfill1');
          }
        }

        .ytbsvpath {
          @include themify($themes) {
            fill: themed('bg3');
          }
        }
      }

      &:hover {
        svg {
          opacity: 0.8;
        }
      }
    }
  }

  &__copyr {
    margin-top: 14px;

    a {
      color: #009688;
      text-decoration: none;

      &:hover {
        text-decoration: underline;
      }
    }
  }
}

.min_title_1 {
  font-style: normal;
  font-weight: 700;
  font-size: 24px;
  line-height: 29px;
  margin-bottom: 26px;
}

.min_title_2 {
  font-style: normal;
  font-weight: 700;
  font-size: 18px;
  line-height: 150%;
  margin-bottom: 22px;
}

.footer__min_mnu {
  list-style-type: none;
  padding-left: 0;

  li {
    margin-bottom: 15px;

    &:last-child {
      margin-bottom: 0;
    }

    a {
      @include themify($themes) {
        color: themed('maincolor1');
      }
      text-decoration: none;

      &:hover {
        opacity: 0.7;
        text-decoration: underline;
        @include themify($themes) {
          color: themed('maincolor1');
        }
      }
    }
  }
}

.f_fl_e {
  display: flex;
  flex-flow: column;
  justify-content: flex-end;
}

.footer_col_3 {
  align-items: flex-end;
}


@media (max-width: 992px) {
  .footer {
    padding-bottom: 0;

    &__social {
      li {
        margin-left: 0px;
        margin-right: 22px;
      }
    }
  }
  .footer_col_3 {
    display: flex;
    align-items: flex-start;
  }
  .footer_col {
    margin-bottom: 40px;
  }
}

@media (max-width: 768px) {
  .footer_col {
    margin-bottom: 30px;
  }
  .footer {
    padding-top: 30px;
    padding-bottom: 0px;
    font-size: 14px;

    p {
      font-size: 14px;
    }

    &__logo {
      margin-bottom: 13px;

      img {
        max-width: 100px;
        margin-left: -5px;
      }
    }

    .pays_p {
      margin-top: 20px;

      .mastercard_1 {
        img {
          max-width: 40px;
        }
      }

      .visa_1 {
        img {
          max-width: 65px;
        }
      }
    }

    &__social {
      margin-top: 12px;

      li {
        margin-left: 0px;
        margin-right: 12px;

        a {
          width: 25px;
          height: 25px;
          border-radius: 6px;

          svg {
            max-width: 60%;
          }
        }
      }
    }

    &__copyr {
      margin-top: 14px;
    }
  }
  .min_title_1 {
    font-size: 18px;
    line-height: 22px;
    margin-bottom: 15px;
  }
  .min_title_2 {
    font-size: 16px;
    line-height: 150%;
    margin-bottom: 15px;
  }
  .footer__min_mnu {
    li {
      margin-bottom: 10px;
    }
  }
}


.questions {
  position: relative;
  padding-top: 55px;
  padding-bottom: 59px;
  z-index: 1;
  @include themify($themes) {
    color: themed('maincolor1');
  }

  &__title {
    font-style: normal;
    font-weight: 500;
    font-size: 42px;
    line-height: 49px;
    margin-bottom: 34px;
    margin-left: 44px;
    max-width: 397px;
  }

  &__p1 {
    font-style: normal;
    font-weight: 500;
    font-size: 20px;
    line-height: 24px;
    margin-bottom: 39px;
    margin-left: 44px;
    max-width: 397px;
  }

  &__left {
    padding-left: 56px;
  }

  .form_1 {
    padding-right: 65px;
  }
}


.form_1__bottom {
  margin-top: 21px;
  display: flex;

  p {
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 130%;
    @include themify($themes) {
      color: themed('maincolor1');
    }

    a {
      color: #2F80ED;
      text-decoration: underline;
    }
  }
}

.btn_2 {
  font-style: normal;
  font-weight: 700;
  font-size: 16px;
  line-height: 19px;
  color: #FFFFFF;
  display: flex;
  align-items: center;
  justify-content: center;
  border: none;
  background: #009688;
  border-radius: 10px;
  width: 143px;
  min-width: 143px;
  height: 53px;
  margin-right: 20px;
}


@media (max-width: 1400px) {
  .questions {
    .form_1 {
      padding-right: 0;
    }
  }
}

@media (max-width: 1200px) {
  .questions {
    &__title {
      font-size: 42px;
      line-height: 49px;
      margin-bottom: 34px;
      margin-left: 0px;
      max-width: 397px;
    }

    &__p1 {
      font-size: 20px;
      line-height: 24px;
      margin-bottom: 39px;
      margin-left: 0px;
      max-width: 397px;
    }

    &__left {
      padding-left: 0px;
    }
  }
}

@media (max-width: 992px) {
  .questions {
    &__title {
      font-size: 34px;
      line-height: 44px;
      margin-bottom: 27px;
      margin-left: 0px;
    }

    &__p1 {
      font-size: 18px;
      line-height: 22px;
      margin-bottom: 39px;
      margin-left: 0px;
    }

    &__left {
      padding-left: 10px;
    }

    &__image {
      img {
        max-width: 90%;
      }
    }
  }
  .btn_2 {
    margin-right: 10px;
    height: 43px;
    width: 125px;
    min-width: 125px;
  }
}

@media (max-width: 768px) {
  .questions {
    padding-top: 30px;

    &__title {
      font-size: 26px;
      line-height: 32px;
      margin-bottom: 20px;
    }

    &__p1 {
      font-size: 16px;
      line-height: 18px;
      margin-bottom: 20px;
      margin-left: 0px;
    }

    &__image {
      display: flex;
      justify-content: center;

      img {
        max-width: 230px;
      }
    }

    .form_1__bottom {
      flex-flow: column-reverse;

      p {
        width: 100%;
        text-align: center;
      }
    }

    .btn_2 {
      margin: 0 auto;
      margin-top: 15px;
    }

    .label1 {
      margin-bottom: 5px;
    }

    .input1 {
      margin-bottom: 10px;
      height: 35px;
    }
  }
}


.pay_page {
  padding: 120px 0;
}

.pay {
  &__sec1 {
    @include themify($themes) {
      background: themed('bg5');
    }
    border-radius: 10px;
    padding: 40px;
    min-width: 100%;
  }

  &__row {
    align-items: center;
  }

  &__image {
    img {
      max-width: 100%;
    }
  }

  &__check_i {
    width: 83px;
    height: 83px;
    @include themify($themes) {
      background-color: themed('bg3');
      border: themed('border3');
    }
    border-radius: 10px;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-bottom: 30px;
  }

  &__title {
    font-style: normal;
    font-weight: 700;
    font-size: 30px;
    line-height: 130%;
    @include themify($themes) {
      color: themed('maincolor1');
    }
    margin-bottom: 6px;
  }

  &__p1 {
    font-style: normal;
    font-weight: 400;
    font-size: 18px;
    line-height: 150%;
    @include themify($themes) {
      color: themed('maincolor1');
    }
    margin-bottom: 30px;
  }

  &__p2 {
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 150%;
    @include themify($themes) {
      color: themed('maincolor1');
    }
  }

  &__to_blog {
    padding: 10px 34px;
    margin-top: 20px;
    font-weight: 700;
    font-size: 16px;
    line-height: 150%;

    @include themify($themes) {
      &:hover {
        background-color: themed('hoverbg');
      }
    }
  }
}

.pay_form {
  &__item {
    input {
      display: none;
    }

    label {
      display: flex;
      width: 100%;
      height: 50px;
    }
  }

  &__sbm {
    padding: 10px 34px;
    font-weight: 700;
    font-size: 16px;
    line-height: 150%;
    margin-top: 20px;
    margin-bottom: 20px;
    line-height: 150%;
    @include themify($themes) {
      &:hover {
        background-color: themed('hoverbg');
      }
    }
  }

  &__agree {
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 150%;
    @include themify($themes) {
      color: themed('maincolor1');
    }

    a {
      color: #2F80ED;
      text-decoration: underline;

      &:hover {
        text-decoration: none;
      }
    }
  }
}

.p_f_item {
  @include themify($themes) {
    background-color: themed('bg3');
  }
  border-radius: 10px;
  margin-bottom: 10px;
  cursor: pointer;

  &:hover {
    box-shadow: 0px 4px 20px rgba(0, 0, 0, 0.05);
  }

  &__image {
    img {
      max-width: 170px;
    }
  }

  &__label {
    display: flex !important;
    min-height: 100%;
    align-items: center;
    justify-content: space-between;
    padding: 10px 20px;
    min-height: 74px;
    cursor: pointer;
  }

  &__name {
    font-style: normal;
    font-weight: 700;
    font-size: 24px;
    line-height: 130%;
    @include themify($themes) {
      color: themed('maincolor1');
    }
    display: flex;
    align-items: center;
  }

  &__min_checkbox {
    margin-right: 20px;
    width: 26px;
    min-width: 26px;
    height: 26px;
    min-height: 26px;
    @include themify($themes) {
      background: themed('bg6');
      border: themed('border4');
    }
    border-radius: 10px;
    display: flex;
    align-items: center;
    justify-content: center;

    svg {
      opacity: 0;
    }
  }
}

.pay_form__input[type=radio]:checked + .p_f_item__label {
  .p_f_item__min_checkbox {
    background: #009688 !important;
    border: 1px solid #009688;

    svg {
      opacity: 1;
    }
  }
}

@media (max-width: 992px) {
  .pay_page {
    padding: 80px 0;
  }
  .pay {
    &__check_i {
      width: 65px;
      height: 65px;
      border-radius: 10px;
      margin-bottom: 30px;

      img {
        max-width: 50%;
      }
    }

    &__sec1 {
      padding: 40px 20px;
    }

    &__title {
      font-size: 26px;
      margin-bottom: 6px;
    }

    &__p1 {
      font-size: 16px;
      margin-bottom: 30px;
    }
  }
  .p_f_item {
    &__image {
      img {
        max-height: 35px;
        max-width: 120px;
      }
    }

    &__name {
      font-size: 18px;
    }

    &__label {
      padding: 10px;
    }

    &__min_checkbox {
      margin-right: 10px;
      width: 24px;
      min-width: 24px;
      height: 24px;
      min-height: 24px;
      border-radius: 8px;

      svg {
        max-width: 60%;
      }
    }
  }
}

@media (max-width: 768px) {
  .pay_page {
    padding: 50px 0;
  }
  .pay {
    &__row {
      flex-flow: column-reverse;
    }

    &__image {
      display: flex;
      justify-content: center;

      img {
        max-width: 280px;
      }
    }

    &__check_i {
      width: 45px;
      height: 45px;
      border-radius: 8px;
      margin-bottom: 15px;

      img {
        max-width: 50%;
      }
    }

    &__sec1 {
      padding: 20px 15px;
    }

    &__title {
      font-size: 22px;
    }

    &__p1 {
      font-size: 14px;
      margin-bottom: 20px;
    }
  }
  .p_f_item {
    &__image {
      img {
        max-height: 27px;
        max-width: 80px;
      }
    }

    &__name {
      font-size: 16px;
    }

    &__label {
      padding: 10px;
      min-height: 55px;
    }

    &__min_checkbox {
      width: 20px;
      min-width: 20px;
      height: 20px;
      min-height: 20px;
      border-radius: 6px;
    }
  }
  .pay_form__sbm {
    font-size: 14px;
    padding: 8px 27px;
    margin: 15px 0;
  }
}


.author {
  margin-top: 20px;
  margin-bottom: 40px;
  padding-bottom: 10px;

  &__wrap {
    display: flex;
  }

  &__avatar {
    width: 200px;
    min-width: 200px;
    height: 200px;
    min-height: 200px;
    overflow: hidden;
    border-radius: 50%;

    img {
      width: 100%;
      object-fit: cover;
    }
  }

  &__avatar_350 {
    width: 350px;
    min-width: 350px;
    height: 350px;
    min-height: 350px;
    overflow: hidden;
    border-radius: 50%;
    margin-left: 40px;
    margin-right: 40px;
    margin-bottom: 20px;

    img {
      width: 100%;
      object-fit: cover;
    }
  }

  &__content {
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 150%;
    @include themify($themes) {
      color: themed('maincolor2');
    }
    padding-left: 30px;
  }

  &__name {
    font-style: normal;
    font-weight: 700;
    font-size: 34px;
    line-height: 130%;
    @include themify($themes) {
      color: themed('maincolor1');
    }
    margin-bottom: 2px;
  }

  &__position {
    font-style: normal;
    font-weight: 400;
    font-size: 18px;
    line-height: 21px;
    @include themify($themes) {
      color: themed('maincolor2');
    }
    margin-bottom: 21px;
    display: block;
  }

  &__min_title {
    display: block;
    font-style: normal;
    font-weight: 700;
    font-size: 18px;
    line-height: 22px;
    @include themify($themes) {
      color: themed('maincolor2');
    }
    margin-bottom: 10px;
  }
}

.author_posts {
  margin-bottom: 80px;

  .alp_wrp {
    margin-top: 50px;
    display: flex;
    justify-content: center;
  }
}

@media (max-width: 992px) {
  .author {
    padding-bottom: 10px;

    &__avatar {
      width: 160px;
      min-width: 160px;
      height: 160px;
      min-height: 160px;
    }

    &__content {
      font-size: 16px;
      padding-left: 30px;
    }

    &__name {
      font-size: 28px;
      margin-bottom: 2px;
    }

    &__position {
      font-size: 16px;
      line-height: 19px;
      margin-bottom: 21px;
    }
  }

  .author_posts {
    margin-bottom: 60px;

    .alp_wrp {
      margin-top: 30px;
    }
  }
}

@media (max-width: 768px) {
  .author {
    padding-bottom: 10px;

    &__wrap {
      flex-flow: column;
    }

    &__avatar {
      width: 120px;
      min-width: 120px;
      height: 120px;
      min-height: 120px;
      margin-bottom: 20px;
    }

    &__content {
      font-size: 14px;
      padding-left: 0px;
    }

    &__name {
      font-size: 24px;
      margin-bottom: 2px;
    }

    &__position {
      font-size: 16px;
      line-height: 19px;
      margin-bottom: 21px;
    }
  }

  .author_posts {
    margin-bottom: 40px;

    .alp_wrp {
      margin-top: 30px;
    }
  }
}


.course_page {
  position: relative;

  .container {
    position: relative;
    z-index: 5;
  }

  .figure1 {
    position: absolute;
    left: -250px;
    top: -140px;
    width: 100%;
    height: 100%;
    z-index: -1;

    @media (max-width: 768px) {
      left: -350px;
      top: -250px;
    }
  }

  .figure2 {
    position: absolute;
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: flex-end;

    img {
      width: 800px;
      height: 800px;
      @media (max-width: 768px) {
        height: 400px;
        height: 400px;
      }
    }
  }

  &__ttle_2 {
    font-style: normal;
    font-weight: 500;
    font-size: 42px;
    line-height: 49px;
    @include themify($themes) {
      color: themed('maincolor1');
    }
    margin-bottom: 80px;
  }
}


.payback {
  padding-bottom: 73px;

  &__row_1 {
    align-items: flex-end;
    margin-bottom: 60px;
  }

  &__title {
    font-style: normal;
    font-weight: 500;
    font-size: 42px;
    line-height: 49px;
    @include themify($themes) {
      color: themed('maincolor1');
    }
  }

  &__txt {
    font-style: normal;
    font-weight: 400;
    font-size: 18px;
    line-height: 21px;
    @include themify($themes) {
      color: themed('maincolor1');
    }
    margin-bottom: 10px;
  }


  &__flx1 {
    display: flex;
    align-items: center;
  }

  &__row_2 {
    display: flex;
    align-items: center;
  }

  .sec_0_2_6 {
    background-image: url(/static/img/bg_grad_6.webp);
    background-size: cover;
    padding: 45px 43px;
    background-repeat: no-repeat;
    max-width: 307px;
    border-radius: 24px;
    overflow: hidden;

    p {
      font-style: normal;
      font-weight: 400;
      font-size: 18px;
      line-height: 21px;
      @include themify($themes) {
        color: themed('maincolor1');
      }
      margin-bottom: 27px;
    }

    span {
      font-style: normal;
      font-weight: 700;
      font-size: 32px;
      line-height: 39px;
      @include themify($themes) {
        color: themed('maincolor1');
      }
    }
  }
}

.salaries {
  margin-left: 50px;
  width: 70%;

  .salaries__main {
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  &__line {
    width: 86%;
    height: 6px;
    @include themify($themes) {
      background: themed('bg11');
    }
    border-radius: 50px;
    position: relative;
    padding-left: 5%;
    padding-right: 5%;
    margin: 0 auto;
  }

  &__polz_inf {
    position: absolute;
    top: -75px;
    width: 120px;
    height: 83px;
    @include themify($themes) {
      background-image: themed('polz_ic');
    }
    background-repeat: no-repeat;
    background-position: center;
    background-size: cover;

    font-style: normal;
    font-weight: 700;
    font-size: 18px;
    line-height: 22px;
    color: #009688;

    display: flex;
    padding-top: 23px;
    justify-content: center;

    margin-left: -50px;

    &::before {
      display: block;
      content: '';
      width: 18px;
      height: 18px;
      background: #009688;
      position: absolute;
      border-radius: 100px;
      z-index: 3;
      bottom: -8px;
    }
  }

  &__bottom {
    display: flex;
    justify-content: space-between;
    padding-top: 36px;
  }

  &__item {
    text-align: center;
    cursor: pointer;
  }

  &__level {
    font-style: normal;
    font-weight: 700;
    font-size: 24px;
    line-height: 29px;
    @include themify($themes) {
      color: themed('maincolor1');
    }
    margin-bottom: 10px;
  }

  &__period {
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 150%;
    @include themify($themes) {
      color: themed('maincolor1');
    }
  }

  .stp_1 {
    left: -15%;
  }

  .stp_2 {
    left: 41%;
  }

  .stp_3 {
    left: 98%;
  }


  .ui-widget.ui-widget-content {
    border: none;
  }


  .ui-state-default, .ui-widget-content .ui-state-default {
    position: relative;
    border: none;
    background-color: none;
  }

  .ui-slider-range-min {
    background-color: transparent;
  }
}

@media (max-width: 992px) {
  .payback {
    padding-bottom: 73px;

    &__row_1 {
      margin-bottom: 40px;
    }

    &__title {
      font-size: 34px;
      line-height: 40px;
    }

    &__txt {
      font-size: 16px;
      line-height: 19px;
      margin-bottom: 10px;
    }

    .sec_0_2_6 {
      padding: 30px 25px;
      max-width: 260px;
      border-radius: 24px;

      p {
        font-size: 16px;
        line-height: 19px;
        margin-bottom: 20px;
      }

      span {
        font-size: 26px;
        line-height: 30px;
      }
    }
  }


  .salaries {
    margin-left: 10px;
    width: 70%;

    &__line {
      width: 78%;
      height: 6px;
      border-radius: 50px;
      padding-left: 5%;
      padding-right: 5%;
    }


    &__polz_inf {
      top: -75px;
      width: 120px;
      height: 83px;

      font-size: 18px;
      line-height: 22px;

      padding-top: 23px;

      &::before {
        width: 18px;
        height: 18px;;
        bottom: -8px;
      }
    }

    &__bottom {
      padding-top: 36px;
    }

    &__level {
      font-size: 20px;
      line-height: 26px;
      margin-bottom: 10px;
    }

    &__period {
      font-size: 14px;
    }

    .stp_1 {
      left: -26%;
    }

    .stp_2 {
      left: 27%;
    }

    .stp_3 {
      left: 83%;
    }
  }
}

@media (max-width: 768px) {
  .payback {
    padding-bottom: 53px;

    &__row_2 {
      flex-flow: column !important;
    }

    &__row_1 {
      margin-bottom: 20px;
    }

    &__title {
      font-size: 26px;
      line-height: 32px;
    }

    &__txt {
      font-size: 14px;
      line-height: 16px;
      margin-top: 15px;
      margin-bottom: 10px;
    }

    .sec_0_2_6 {
      padding: 15px 15px;
      max-width: 100%;
      width: 100%;
      border-radius: 15px;
      background-size: cover;
      background-position: center;

      p {
        font-size: 14px;
        line-height: 16px;
        margin-bottom: 7px;
      }

      span {
        font-size: 20px;
        line-height: 26px;
      }
    }
  }


  .salaries {
    margin-left: 0px;
    width: 100%;
    margin-top: 80px;

    &__line {
      width: 84%;
      height: 6px;
      border-radius: 50px;
      padding-left: 5%;
      padding-right: 5%;
    }


    &__polz_inf {
      top: -66px;
      width: 110px;
      height: 73px;

      font-size: 14px;
      line-height: 16px;

      padding-top: 20px;

      &::before {
        width: 14px;
        height: 14px;;
        bottom: -7px;
      }
    }

    &__bottom {
      padding-top: 25px;
    }

    &__level {
      font-size: 14px;
      line-height: 16px;
      margin-bottom: 10px;
    }

    &__period {
      font-size: 12px;
    }

    .stp_1 {
      left: -19%;
    }

    .stp_2 {
      left: 35%;
    }

    .stp_3 {
      left: 92%;
    }
  }
}

@media (max-width: 576px) {
  .salaries {
    margin-left: 0px;
    width: 299px;
    margin-top: 80px;

    &__line {
      width: 230px;
    }


    .stp_1 {
      left: -30%;
    }

    .stp_2 {
      left: 23%;
    }

    .stp_3 {
      left: 75%;
    }
  }
}


.forwich {
  margin-bottom: 30px;

  &__title {
    margin-bottom: 30px;
  }

  &__item {
    margin-bottom: 30px;
  }

  &__img {
    margin-bottom: 16px;
  }

  &__title2 {
    font-style: normal;
    font-weight: 700;
    font-size: 24px;
    line-height: 29px;
    @include themify($themes) {
      color: themed('maincolor1');
    }
    margin-bottom: 24px;
  }

  &__txt {
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 130%;
    @include themify($themes) {
      color: themed('maincolor1');
    }
  }
}


@media (max-width: 992px) {
  .forwich {
    margin-bottom: 30px;

    &__title {
      margin-bottom: 75px !important;
    }

    &__item {
      margin-bottom: 30px;
    }

    &__img {
      margin-bottom: 16px;

      img {
        max-width: 70px;
      }
    }

    &__title2 {
      font-size: 20px;
      line-height: 25px;
      margin-bottom: 20px;
    }

    &__txt {
      font-size: 14px;
    }
  }
}

@media (max-width: 768px) {
  .forwich {
    margin-bottom: 20px;

    &__title {
      margin-bottom: 30px !important;
    }

    &__item {
      margin-bottom: 30px;
    }

    &__img {
      margin-bottom: 16px;

      img {
        max-width: 60px;
      }
    }

    &__title2 {
      font-size: 18px;
      line-height: 22px;
      margin-bottom: 15px;
    }

    &__txt {
      font-size: 14px;
    }
  }
}


.course_program {
  margin-top: 30px;
  margin-bottom: 30px;

  &__title {
    font-style: normal;
    font-weight: 600;
    font-size: 80px;
    line-height: 95px;
    @include themify($themes) {
      color: themed('maincolor1');
    }
    position: relative;
    margin-top: -14px;

    .btn_green {
      position: absolute;
      bottom: 20px;
      right: -10px;
      display: inline;
    }
  }

  &__txt {
    font-style: normal;
    font-weight: 400;
    font-size: 18px;
    line-height: 150%;
    @include themify($themes) {
      color: themed('maincolor1');
    }
    padding-left: 50px;
  }

  &__numbers {
    padding-left: 43px;
  }

  &__num_item {
    display: flex;
    align-items: flex-end;
    margin-bottom: 20px;
  }

  &__numb_item {
    font-style: normal;
    font-weight: 700;
    font-size: 32px;
    line-height: 39px;
    background: linear-gradient(94.37deg, #805FFF -72.72%, #24F3DF 109.09%);
    -webkit-background-clip: text;
    text-transform: uppercase;
    -webkit-text-fill-color: transparent;
    color: #0B2349;
    display: block;
  }

  &__num_lable {
    font-style: normal;
    font-weight: 400;
    font-size: 18px;
    line-height: 30px;
    @include themify($themes) {
      color: themed('maincolor1');
    }
    margin-left: 10px;
  }
}


@media (max-width: 1200px) {
  .course_program {
    margin-top: 30px;
    margin-bottom: 30px;

    &__title {
      font-size: 60px;
      line-height: 75px;
      margin-top: -14px;

      .btn_green {
        bottom: 13px;
        right: -25px;
        padding: 13px 20px;
      }
    }
  }
  .course_program__numbers {
    padding-left: 10px;
  }
}


@media (max-width: 992px) {
  .course_program {
    margin-top: 20px;
    margin-bottom: 20px;

    &__title {
      font-size: 60px;
      line-height: 70px;
      margin-top: 0px;
      margin-bottom: 50px;

      .btn_green {
        bottom: 13px;
        // right: 0;
        left: 186px;
        display: block;
        max-width: 140px;
        padding: 13px 20px;
      }
    }

    &__txt {
      margin-left: 0;
      padding-left: 0;
    }
  }
}


@media (max-width: 768px) {
  .course_program {
    margin-top: 20px;
    margin-bottom: 20px;

    &__title {
      font-size: 40px;
      line-height: 50px;
      margin-bottom: 50px;

      .btn_green {
        bottom: 10px;
        left: 136px;
        max-width: 140px;
        padding: 8px 20px;
      }
    }

    &__txt {
      font-size: 14px;
    }

    &__numbers {
      padding-left: 43px;
    }

    &__num_item {
      margin-bottom: 20px;
    }

    &__numb_item {
      font-size: 26px;
      line-height: 32px;
    }

    &__num_lable {
      font-size: 14px;
      line-height: 30px;
    }
  }
}


@media (max-width: 576px) {
  .course_program {
    margin-top: 20px;
    margin-bottom: 20px;

    &__title {
      font-size: 40px;
      line-height: 50px;
      margin-bottom: 50px;

      .btn_green {
        bottom: 10px;
        left: 136px;
        max-width: 140px;
        padding: 8px 20px;
      }
    }

    &__txt {
      font-size: 14px;
    }

    &__numbers {
      margin-top: 30px;
      padding-left: 0px;
    }

    &__num_item {
      margin-bottom: 15px;
    }

    &__numb_item {
      font-size: 26px;
      line-height: 32px;
    }
  }
}


.y_portfolio_end {
  padding-bottom: 61px;

  &__title {
    margin-bottom: 108px;
  }

  &__item {
    margin-bottom: 30px;
  }

  &__title2 {
    font-style: normal;
    font-weight: 700;
    font-size: 24px;
    line-height: 29px;
    @include themify($themes) {
      color: themed('maincolor1');
    }
    margin-bottom: 16px;
  }

  &__txt {
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 150%;
    @include themify($themes) {
      color: themed('maincolor1');
    }
  }
}

@media (max-width: 992px) {
  .y_portfolio_end {
    padding-bottom: 61px;

    &__title {
      margin-bottom: 78px !important;
    }

    &__title2 {
      font-size: 22px;
      line-height: 27px;
      margin-bottom: 15px;
    }

    &__txt {
      font-size: 14px;
    }
  }
}

@media (max-width: 768px) {
  .y_portfolio_end {
    padding-bottom: 0px;

    &__title {
      margin-bottom: 58px !important;
    }

    &__title2 {
      font-size: 20px;
      line-height: 25px;
    }

    &__txt {
      font-size: 14px;
    }
  }
}


.cp_tyou_resume {
  padding-top: 60px;
  padding-bottom: 29px;

  &__flx_1 {
    display: flex;
    align-items: center;
  }

  &__avatar {
    width: 70px;
    height: 70px;
    border-radius: 50%;
    overflow: hidden;
    margin-right: 30px;

    img {
      width: 100%;
    }
  }

  &__sec_1 {

  }

  &__sec_2_wrap {
    display: flex;
    justify-content: space-between;
    width: 100%;
  }

  &__sec_2 {
    // display: flex;
  }

  &__position {
    font-style: normal;
    font-weight: 400;
    font-size: 18px;
    line-height: 21px;
    @include themify($themes) {
      color: themed('maincolor1');
    }
    margin-bottom: 2px;
    display: block;
  }

  &__position_2 {
    margin-bottom: 2rem;
    font-style: normal;
    font-weight: 700;
    font-size: 34px;
    line-height: 130%;
    @include themify($themes) {
      color: themed('maincolor1');
    }
  }

  &__salary {
    span {
      font-style: normal;
      font-weight: 400;
      font-size: 18px;
      line-height: 21px;
      @include themify($themes) {
        color: themed('maincolor1');
      }
      margin-bottom: 2px;
      display: block;
    }

    div {
      font-style: normal;
      font-weight: 700;
      font-size: 34px;
      line-height: 130%;
      @include themify($themes) {
        color: themed('maincolor1');
      }
    }
  }
}

.prof_skill {
  margin-top: 70px;

  &__title {
    margin-bottom: 50px;
    font-style: normal;
    font-weight: 400;
    font-size: 24px;
    line-height: 28px;
    @include themify($themes) {
      color: themed('maincolor1');
    }
  }

  &__item {
    margin-bottom: 42px;
    display: flex;
  }

  &__txt {
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 130%;
    @include themify($themes) {
      color: themed('maincolor1');
    }
    margin-left: 8px;
    max-width: 210px;
  }
}

@media (max-width: 1200px) {
  .cp_tyou_resume {
    &__m_title {
      font-size: 35px;
      line-height: 42px;
    }

    &__position_2 {
      font-size: 26px;
    }

    &__salary {
      div {
        font-size: 26px;
      }
    }
  }
}

@media (max-width: 992px) {
  .cp_tyou_resume {
    &__row {
      flex-flow: column;
    }
  }
}

@media (max-width: 768px) {
  .cp_tyou_resume {
    padding-top: 30px;
    padding-bottom: 15px;

    &__m_title {
      font-size: 26px;
      line-height: 34px;
      margin-bottom: 40px !important;
    }

    &__avatar {
      width: 50px;
      height: 50px;
      margin-right: 15px;
    }

    &__sec_2_wrap {
      width: 100%;
    }

    &__position {
      font-size: 16px;
      line-height: 19px;
      margin-bottom: 2px;
    }

    &__position_2 {
      font-size: 18px;
    }

    &__salary {
      margin-left: 15px;

      span {
        font-weight: 400;
        font-size: 16px;
        line-height: 19px;
        margin-bottom: 2px;
      }

      div {
        font-size: 18px;
        line-height: 130%;
      }
    }
  }
  .prof_skill {
    margin-top: 30px;

    &__title {
      margin-bottom: 25px;
      font-size: 22px;
      line-height: 26px;
    }

    &__item {
      margin-bottom: 25px;
    }

    &__ic {
      svg {
        width: 14px;
        height: 14px;
      }
    }

    &__txt {
      font-size: 14px;
      line-height: 130%;
      margin-left: 6px;
      max-width: 90%;
    }
  }
}

@media (max-width: 375px) {
  .cp_tyou_resume {
    &__flx_1 {
      // flex-wrap: wrap;
      align-items: flex-start;
    }

    &__sec_2_wrap {
      flex-wrap: wrap;
    }

    &__salary {
      min-width: 100%;
      margin-left: 0;
      margin-top: 15px;
    }
  }
}


.text_sec_1_5__type3 {
  .text_sec_1_5__sec2 {
    justify-content: center;
  }
}

.text_sec_1_5__type2 {
  .text_sec_1_5__sec2 {
    justify-content: flex-start;
  }
}

@media (max-width: 992px) {
  .text_sec_1_5__type2 {
    .row {
      flex-flow: column-reverse;
    }
  }
  .text_sec_1_5__type3 {
    .text_sec_1_5__sec2 {
      padding-top: 40px;
    }
  }
}


.signup_course_s {
  &__title {
    font-style: normal;
    font-weight: 600;
    font-size: 42px;
    line-height: 45px;
    @include themify($themes) {
      color: themed('maincolor1');
    }
    margin-bottom: 27px;
  }

  &__p1 {
    font-style: normal;
    font-weight: 400;
    font-size: 20px;
    line-height: 140%;
    @include themify($themes) {
      color: themed('maincolor1');
    }
    margin-bottom: 32px;
    max-width: 393px;
  }

  &__sec2 {
    min-height: 100%;
    display: flex;
    align-items: flex-end;
  }
}


@media (max-width: 992px) {

  .signup_course_s {
    &__title {
      font-size: 42px;
      line-height: 42px;
      margin-bottom: 22px;
    }

    &__p1 {
      font-size: 18px;
      margin-bottom: 27px;
      max-width: 393px;
    }

    &__sec2 {
    }
  }
}

@media (max-width: 768px) {

  .signup_course_s {
    padding-bottom: 20px !important;

    .row {
      flex-flow: column-reverse;
    }

    &__title {
      font-size: 30px;
      line-height: 30px;
      margin-bottom: 22px;
    }

    &__p1 {
      font-size: 16px;
      margin-bottom: 27px;
      max-width: 393px;
    }

    &__sec2 {
      justify-content: center;
    }
  }
}


@media (max-width: 768px) {
  .text_sec_1_5__co_page {
    .text_sec_1_5__sec2 {
      display: flex;
      justify-content: center;
    }

    img {
      max-width: 340px;
    }
  }
}


.authors_section {

  &__wrap {
    padding-top: 10px;
    padding-bottom: 10px;
  }

  &__item {
    display: flex;
    flex-flow: column;
    align-items: center;
    margin-bottom: 30px;
  }

  &__img {
    width: 150px;
    height: 150px;
    border-radius: 50%;
    overflow: hidden;
    display: flex;

    img {
      width: 100%;
    }
  }

  &__name {
    font-style: normal;
    font-weight: 700;
    font-size: 24px;
    line-height: 29px;
    text-align: center;
    @include themify($themes) {
      color: themed('maincolor1');
    }
    margin-top: 24px;
    margin-bottom: 6px;
  }

  &__position {
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 130%;
    text-align: center;
    @include themify($themes) {
      color: themed('maincolor1');
    }
  }

  &__text {
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 130%;
    text-align: center;
    @include themify($themes) {
      color: themed('maincolor1');
    }
    max-width: 300px;
    margin-top: 20px;
  }
}

@media (max-width: 992px) {
  .authors_section {
    &__wrap {
      padding-top: 10px;
      padding-bottom: 10px;
    }

    &__img {
      width: 150px;
      height: 150px;
    }

    &__name {
      font-size: 20px;
      line-height: 24px;
      margin-top: 24px;
      margin-bottom: 6px;
    }

    &__position {
      font-size: 14px;
    }

    &__text {
      font-size: 14px;
      margin-top: 20px;
    }
  }
}

@media (max-width: 768px) {
  .authors_section {
    .title_3 {
      text-align: center;
    }

    &__wrap {
      padding-top: 10px;
      padding-bottom: 10px;
    }

    &__img {
      width: 150px;
      height: 150px;
    }
  }
}


.faq_section {
  padding-top: 40px;
  position: relative;
  overflow: hidden;

  .bg-image-1 {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
  }
}

.accordion_two {
  margin-bottom: 50px;
  background-color: transparent;

  &__content {
    font-family: 'Gilroy', sans-serif;
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 150%;
    @include themify($themes) {
      color: themed('maincolor1');
    }
  }

  .accordion-item {
    background-color: transparent;
    border: none !important;
  }

  .accordion-button::after {
    mask-image: url(/static/img/icons/+.svg);
    background-image: none;
    @include themify($themes) {
      background-color: themed('maincolor1');
    }
    mask-size: contain;
    mask-repeat: no-repeat;
    mask-position: center;
    width: 26px;
    height: 26px;
  }

  .accordion-button:not(.collapsed)::after {
    mask-image: url(/static/img/icons/-.svg);
    background-image: none;
    background-color: #181818;
    mask-size: contain;
    mask-repeat: no-repeat;
    mask-position: center;
    width: 26px;
    height: 26px;
  }

  .accordion-button {
    background-color: transparent;
    font-family: 'Gilroy', sans-serif;
    font-style: normal;
    font-weight: 700;
    font-size: 24px;
    line-height: 29px;
    @include themify($themes) {
      color: themed('maincolor1');
    }
    padding: 20px 0;
    border-bottom: none;

    &:not(.collapsed)::after {
      background-color: #009688;
    }
  }

  .accordion-button:focus {
    background-color: transparent;
    box-shadow: none;
  }

  .accordion-button {
    background-color: transparent;
    box-shadow: none;
  }

  .accordion-button:not(.collapsed) {
    @include themify($themes) {
      color: themed('maincolor1');
    }
  }

  .accordion-body {
    padding: 0px;
  }
}


@media (max-width: 992px) {

  .course_page {
    &__ttle_2 {
      font-size: 34px;
      line-height: 38px;
      margin-bottom: 40px;
    }
  }

  .faq_section {
    padding-top: 20px;


  }

  .accordion_two {
    margin-bottom: 50px;

    &__content {
      font-size: 14px;
      line-height: 150%;
    }

    .accordion-button::after {
      width: 18px;
      height: 18px;
    }

    .accordion-button:not(.collapsed)::after {
      width: 18px;
      height: 18px;
    }

    .accordion-button {
      font-size: 20px;
      line-height: 25px;
      padding: 20px 0;
    }
  }
}

@media (max-width: 768px) {

  .course_page {
    &__ttle_2 {
      font-size: 26px;
      line-height: 32px;
      margin-bottom: 20px;
    }
  }

  .faq_section {
    padding-top: 20px;


  }

  .accordion_two {
    margin-bottom: 50px;

    &__content {
      font-size: 14px;
      line-height: 150%;
    }

    .accordion-button::after {
      width: 12px;
      height: 12px;
    }

    .accordion-button:not(.collapsed)::after {
      width: 12px;
      height: 12px;
    }

    .accordion-button {
      font-size: 16px;
      line-height: 20px;
      padding: 15px 0;
    }
  }
}


.course_content_page {
  position: relative;

  .container {
    position: relative;
    z-index: 5;
  }

  .figure1 {
    position: absolute;
    left: -250px;
    top: -140px;
    width: 100%;
    height: 100%;
    z-index: -1;

    @media (max-width: 768px) {
      left: -350px;
      top: -250px;
    }
  }

  .figure2 {
    position: absolute;
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: flex-end;

    img {
      width: 800px;
      height: 800px;
      @media (max-width: 768px) {
        height: 400px;
        height: 400px;
      }
    }
  }

  &__ttle_2 {
    font-style: normal;
    font-weight: 500;
    font-size: 42px;
    line-height: 49px;
    @include themify($themes) {
      color: themed('maincolor1');
    }
    margin-bottom: 60px;
  }
}

.section_main_2 {
  margin-top: 75px;
  margin-bottom: 50px;
  display: flex;

  &__title_1 {
    font-style: normal;
    font-weight: 600;
    font-size: 60px;
    line-height: 70px;
    @include themify($themes) {
      color: themed('maincolor1');
    }
    margin-bottom: 14px;
  }

  &__content {
    font-style: normal;
    font-weight: 400;
    font-size: 24px;
    line-height: 140%;
    @include themify($themes) {
      color: themed('maincolor1');
    }
  }

  &__info {
    max-width: 584px;

  }

  &__btn {
    display: inline-flex;
    margin-top: 54px;
  }

  &__image {
    margin-left: 96px;
    padding-right: 50px;

    img {
      width: 300px;
      max-width: 300px;
    }
  }
}


.accordion_one {
  margin-bottom: 50px;

  .accordion-item {
    border-top: 1px solid !important;
    border-bottom: 1px solid !important;
    border: 1px solid;
    border-radius: 24px;
    margin-top: -1px;
    @include themify($themes) {
      background: themed('bg3');
      border-color: themed('bordercolor4');
    }
  }

  // .accordion-button::after{
  //     background-image: url(/static/img/icons/+.svg);
  // }
  // .accordion-button:not(.collapsed)::after{
  //     background-image: url(/static/img/icons/-.svg);
  // }
  .accordion-button::after {
    mask-image: url(/static/img/icons/+.svg);
    background-image: none;
    @include themify($themes) {
      background-color: themed('maincolor1');
    }
    mask-size: contain;
    mask-repeat: no-repeat;
    mask-position: center;
    width: 26px;
    height: 26px;
  }

  .accordion-button:not(.collapsed)::after {
    mask-image: url(/static/img/icons/-.svg);
    @include themify($themes) {
      background-color: themed('maincolor1');
    }
    background-image: none;
    // background-color: #181818;
    mask-size: contain;
    mask-repeat: no-repeat;
    mask-position: center;
    width: 26px;
    height: 26px;
  }

  .accordion-button {
    background-color: transparent;
    font-family: 'Gilroy', sans-serif;
    font-style: normal;
    font-weight: 700;
    font-size: 36px;
    line-height: 44px;
    @include themify($themes) {
      color: themed('maincolor1');
    }
    padding: 30px 39px 30px 30px;
    border-bottom: none;
  }

  .accordion-button:focus {
    background-color: transparent;
    box-shadow: none;
  }

  .accordion-button {
    background-color: transparent;
    box-shadow: none;
  }

  .accordion-button:not(.collapsed) {
    @include themify($themes) {
      color: themed('maincolor1');
    }
  }

  .accordion-body {
    padding: 10px 30px 35px 30px;
  }
}

.c_c_block {
  margin-bottom: 50px;

  &:last-child {
    margin-bottom: 0;
  }

  &__title {
    font-style: normal;
    font-weight: 700;
    font-size: 32px;
    line-height: 39px;
    color: #009688;
  }

  &__title_2 {
    font-style: normal;
    font-weight: 700;
    font-size: 24px;
    line-height: 29px;
    @include themify($themes) {
      color: themed('maincolor1');
    }
    margin-bottom: 10px;
  }

  &__content {
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 150%;
    @include themify($themes) {
      color: themed('maincolor1');
    }
  }

  &__section {
    margin-top: 40px;

    &:last-child {
      margin-bottom: 0;
    }
  }

  &__finish {
    .c_c_block__section {
      display: flex;
    }

    .c_c_block__image {
      margin-right: 37px;
    }
  }
}


@media (max-width: 992px) {

  .course_content_page {
    &__ttle_2 {
      font-size: 35px;
      line-height: 42px;
      margin-bottom: 40px;
    }
  }
  .section_main_2 {
    margin-top: 55px;
    margin-bottom: 35px;

    &__title_1 {
      font-size: 40px;
      line-height: 50px;
    }

    &__content {
      font-size: 20px;
    }

    &__btn {
      margin-top: 54px;
    }

    &__image {
      margin-left: 46px;
      padding-right: 0px;

      img {
        width: 180px;
        max-width: 180px;
      }
    }
  }

  .accordion_one {
    margin-bottom: 50px;

    .accordion-item {
      border-radius: 24px;
    }

    .accordion-button {
      font-size: 30px;
      line-height: 34px;
      padding: 20px 20px 20px 20px;
    }

    .accordion-body {
      padding: 10px 20px 35px 20px;
    }
  }

  .c_c_block {
    margin-bottom: 40px;

    &__title {
      font-size: 28px;
      line-height: 39px;
    }

    &__title_2 {
      font-size: 22px;
      line-height: 27px;
      margin-bottom: 10px;
    }

    &__content {
      font-size: 16px;
    }

    &__section {
      margin-top: 30px;
    }

    &__finish {
      .c_c_block__image {
        margin-right: 20px;

        img {
          max-width: 80px;
        }
      }
    }
  }
}


@media (max-width: 768px) {
  .course_content_page {
    &__ttle_2 {
      font-size: 28px;
      line-height: 34px;
      margin-bottom: 20px;
    }

    .accordion_one {
      margin-bottom: 20px;
    }
  }
  .section_main_2 {
    margin-top: 20px;
    margin-bottom: 20px;

    &__title_1 {
      font-size: 30px;
      line-height: 38px;
    }

    &__content {
      font-size: 16px;
    }

    &__btn {
      margin-top: 35px;
    }

    &__image {
      margin-left: 10px;
      padding-right: 0px;

      img {
        width: 130px;
        max-width: 130px;
      }
    }
  }

  .accordion_one {
    margin-bottom: 50px;

    .accordion-item {
      border-radius: 24px;
    }

    .accordion-button {
      font-size: 24px;
      line-height: 30px;
      padding: 15px 15px 15px 15px;
    }

    .accordion-body {
      padding: 10px 15px 20px 15px;
    }

    .accordion-button::after {
      width: 1rem;
      height: 1rem;
      background-size: contain;
    }
  }

  .c_c_block {
    margin-bottom: 30px;

    &__title {
      font-size: 24px;
      line-height: 27px;
    }

    &__title_2 {
      font-size: 18px;
      line-height: 27px;
      margin-bottom: 10px;
    }

    &__content {
      font-size: 14px;
    }

    &__section {
      margin-top: 20px;
    }

    &__finish {
      .c_c_block__image {
        margin-right: 10px;

        img {
          max-width: 50px;
        }
      }
    }
  }
}

@media (max-width: 576px) {
  .section_main_2 {

    &__image {
      margin-left: 10px;

      img {
        width: 90px;
        max-width: 90px;
      }
    }

    &__btn {
      margin-top: 25px;
      font-size: 14px;
      padding: 13px 20px;
    }
  }
}


.article {

  &__image {
    width: 100%;
    border-radius: 24px;
    overflow: hidden;
    position: relative;
    justify-content: center;
    align-items: center;
    @media print {
      display: none;
    }
    img {
      min-height: 100%;
      min-width: 100%;
    }

    .p_m_l_2 {
      padding: 10px 16px;
      font-size: 14px;
      line-height: 17px;
      bottom: 24px;
      left: 24px;
    }
  }

  &__article_inf1 {
    display: flex;
    align-items: center;
    margin-top: 30px;
    margin-bottom: 20px;
    @media print {
      display: none;
    }
  }

  &__author {
    display: flex;
    align-items: center;
    margin-right: 17px;
  }

  &__author_img {
    width: 44px;
    height: 44px;
    border-radius: 50%;
    overflow: hidden;

    img {
      width: 100%;
      object-fit: cover;
    }
  }

  &__author_name {
    font-style: normal;
    font-weight: 700;
    font-size: 20px;
    line-height: 24px;
    text-decoration: none;
    @include themify($themes) {
      color: themed('maincolor1');
    }
    margin-left: 12px;
  }

  &__date {
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 19px;
    @include themify($themes) {
      color: themed('color5');
    }
  }

  &__labels {
    display: flex;
    flex-wrap: wrap;
    margin-bottom: 20px;
  }

  &__title1 {
    font-style: normal;
    font-weight: 700;
    font-size: 34px;
    line-height: 130%;
    @include themify($themes) {
      color: themed('maincolor1');
    }
    margin-bottom: 30px;
  }

  &__title2 {
    font-style: normal;
    font-weight: 700;
    font-size: 30px;
    line-height: 130%;
    @include themify($themes) {
      color: themed('maincolor1');
    }
    margin-bottom: 30px;
  }

  &__title3 {
    font-style: normal;
    font-weight: 700;
    font-size: 24px;
    line-height: 130%;
    @include themify($themes) {
      color: themed('maincolor1');
    }
    margin-bottom: 20px;
  }

  &__p1 {
    margin-bottom: 30px;
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 150%;
    @include themify($themes) {
      color: themed('maincolor1');
    }
  }

  &__course_1 {
    margin-top: 30px;
    margin-bottom: 30px;
    @media print {
      display: none;
    }
    .courses_i {
      padding-left: 60px;
      padding-right: 65px;
    }

    .courses_i__text {
      font-size: 16px;
      line-height: 19px;
    }

    .courses_i__content {
      margin-left: 65px;
    }
  }

  &__note {
    @include themify($themes) {
      background: themed('bg12');
    }
    border-radius: 24px;
    padding: 24px;
    margin-top: 30px;
    margin-bottom: 30px;
  }

  &__p2 {
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 150%;
    @include themify($themes) {
      color: themed('maincolor1');
    }
  }


  &__video {
    margin-top: 30px;
    margin-bottom: 30px;
    width: 100%;

    iframe {
      width: 100%;
      height: 440px;
    }
  }

}

.g_txt {
  color: #009688;
}

.g_bld {
  font-weight: 700;
}

.label_05 {
  padding: 6px 13px;
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 16px;
  display: flex;
  border: 1px solid;
  @include themify($themes) {
    color: themed('maincolor1');
    border-color: themed('bordercolor5');
  }
  border-radius: 10px;
  margin-right: 4px;
  margin-bottom: 4px;

  span {
    color: #009688 !important;
    padding-right: 4px;
  }
}

.code_sec {
  margin-top: 30px;
  margin-bottom: 30px;
  @include themify($themes) {
    background-color: themed('bg13');
  }
  border-radius: 24px;
  padding: 24px 20px 24px 34px;
  font-family: 'Consolas', sans-serif !important;
}

.list_dots {
  margin-top: 30px;
  margin-bottom: 30px;

  li {
    font-style: normal;
    font-weight: 400;
    font-size: 18px;
    line-height: 150%;
    @include themify($themes) {
      color: themed('maincolor1');
    }
    margin-bottom: 25px;
    position: relative;
    padding-left: 40px;

    &::before {
      position: absolute;
      display: block;
      content: '';
      width: 8px;
      height: 8px;
      @include themify($themes) {
        background-color: themed('maincolor1');
      }
      border-radius: 50px;
      top: 10px;
      left: 12px;
    }

    &:last-child {
      margin-bottom: 0;
    }
  }
}

.list_num {
  margin-top: 30px;
  margin-bottom: 30px;
  list-style-type: decimal;
  padding-left: 35px;

  li {
    font-style: normal;
    font-weight: 700;
    font-size: 18px;
    line-height: 150%;
    @include themify($themes) {
      // background-color: themed('maincolor1');
      color: themed('maincolor1');
    }
    margin-bottom: 25px;
  }

  span {
    font-style: normal;
    font-weight: 400;
    font-size: 18px;
    line-height: 150%;
    @include themify($themes) {
      color: themed('maincolor1');
    }
    padding-left: 7px;
  }
}


.table_1 {
  margin-top: 30px;
  margin-bottom: 30px;

  &__head {
    display: flex;
    font-family: 'Gilroy', sans-serif;
    font-style: normal;
    font-weight: 700;
    font-size: 18px;
    line-height: 150%;
    border-bottom: 1px solid;
    padding: 20px 0;
    @include themify($themes) {
      color: themed('maincolor1');
      border-color: themed('maincolor1');
    }
  }

  &__h_item {
    &:nth-child(1) {
      width: 60%;
    }

    &:nth-child(2) {
      width: 40%;
    }

    &:nth-child(3) {
      width: 100px;
      text-align: right;
    }
  }

  &__body {
    margin-top: 10px;
  }

  &__b_line {
    display: flex;
    border-bottom: 1px solid #E7E7E7;
    padding: 10px 0;
  }

  &__b_item {
    &:nth-child(1) {
      width: 60%;
      font-family: 'Gilroy', sans-serif;
      font-style: normal;
      font-weight: 400;
      font-size: 16px;
      line-height: 150%;
      @include themify($themes) {
        color: themed('maincolor1');
      }
    }

    &:nth-child(2) {
      width: 40%;

    }

    &:nth-child(3) {
      width: 100px;
      text-align: left;
      font-family: 'Gilroy', sans-serif;
      font-style: normal;
      font-weight: 400;
      font-size: 16px;
      line-height: 150%;
      @include themify($themes) {
        color: themed('maincolor1');
      }
    }
  }

  .cl_infx_none {
    font-family: 'Gilroy', sans-serif;
    font-style: italic;
    font-weight: 400;
    font-size: 16px;
    line-height: 150%;
    @include themify($themes) {
      color: themed('maincolor1');
    }
  }

  .cl_infx_01 {
    font-family: 'Gilroy', sans-serif;
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 130%;
    color: #CB6A7B;
  }
}

.table {
  @include themify($themes) {
    color: themed('maincolor1');
  }
}


.subscribe_2 {
  padding-top: 30px;
  padding-bottom: 30px;
  box-shadow: 0px 4px 20px rgba(0, 0, 0, 0.05);
  backdrop-filter: blur(20px);
  border-radius: 24px;
  border-left: 3px solid;
  border-right: 3px solid;
  @media print {
    display: none !important;
  }
  @include themify($themes) {
    background: themed('bg8');
    border-left-color: themed('bordercolor6');
    border-right-color: themed('bordercolor7');
  }

  svg {
    path:first-child {
      @include themify($themes) {
        fill: themed('svgfill1');
      }
    }

    .ytb_pth {
      @include themify($themes) {
        fill: themed('ytb_pth');
      }
    }
  }

  &__social {
    display: flex;

    li {
      display: flex;
      align-items: center;
      justify-content: center;

      width: 42px;
      height: 42px;
      margin-right: 8px;

      background: rgba(255, 255, 255, 0.4);
      border: 1px solid rgba(255, 255, 255, 0.4);
      box-shadow: 0px 4px 20px rgba(0, 0, 0, 0.05);
      border-radius: 10px;
      @include themify($themes) {
        background: themed('bg2');
        border: themed('border1');
        box-shadow: themed('bshadow1');
      }
    }

    a {
      display: flex;
      align-items: center;
      justify-content: center;
    }
  }

}

.article_wrap {
  display: flex;
  // flex-flow: row;
  // align-items: flex-start;
}

.maybe_interest {
  margin-top: 40px;
  padding-bottom: 50px;
  @media print {
    display: none;
  }
  &__title {
    font-weight: 700;
    font-size: 24px;
    line-height: 130%;
    margin-bottom: 30px;
  }
}

.article_wrap {
  position: relative;
  display: flex;
  align-items: stretch;
}

.article {
  height: 100%;
}

.article_sidebar_2 {
  flex-shrink: 0;
}

.sidebar_nav {
  position: -webkit-sticky;
  position: sticky;
  top: 30px;
  margin-top: 30px;
  max-height: 80vh;
  overflow-y: auto;
  &__title {
    font-family: 'Gilroy', sans-serif;
    font-style: normal;
    font-weight: 700;
    font-size: 20px;
    line-height: 130%;
    @include themify($themes) {
      color: themed('maincolor1');
    }
    margin-bottom: 20px;
  }

  &__title2 {
    font-family: 'Gilroy', sans-serif;
    font-style: normal;
    font-weight: 400;
    font-size: 18px;
    line-height: 130%;
    @include themify($themes) {
      color: themed('maincolor1');
    }
    display: inline-block;
    margin-bottom: 16px;
    position: relative;
    padding-left: 23px;

    &::before {
      position: absolute;
      display: block;
      content: '';
      width: 6px;
      height: 6px;
      border-radius: 50px;
      background: #009688;
      left: 0;
      top: 9px;
    }
  }

  &__list {
    padding-left: 0;

    a {
      text-decoration: none;
    }
  }

  &__dropdown {
    // overflow: hidden;
    // max-height: 0;
    // height: 0 auto;
    // transition: max-height 0.8s;
    padding-left: 23px;

    li {
      margin-bottom: 14px;
      padding-left: 20px;
      position: relative;

      &::before {
        position: absolute;
        top: 5px;
        left: 0px;
        display: block;
        content: '';
        width: 6px;
        height: 6px;
        border-radius: 50px;
        @include themify($themes) {
          background: themed('maincolor1');
        }
      }

      &:last-child {
        margin-bottom: 18px;
      }

      a {
        font-family: 'Gilroy', sans-serif;
        font-style: normal;
        font-weight: 400;
        font-size: 14px;
        line-height: 130%;
        @include themify($themes) {
          color: themed('maincolor1');
        }
      }
    }

    .sidebar_nav__dropdown__item_active {
      a {
        color: #009688 !important;
      }

      &::before {
        background: #009688;
      }
    }
  }

  .sidebar_nav__dropdown__active {
    .sidebar_nav__dropdown {
      height: 100%;
      max-height: 1000px !important;
      // transition: max-height 0.4s;
    }

    .sidebar_nav__title2 {
      color: #009688 !important;

      &::before {
        display: flex;
        align-items: center;
        justify-content: center;
        width: 6px;
        height: 6px;
        border-radius: 0px;
        background-color: #009688;
        left: 0;
        top: 3px;
        width: 16px;
        height: 16px;
        mask-image: url(/static/img/icons/arr_04.svg);
        mask-repeat: no-repeat;
        mask-position: center;
        mask-size: contain;
      }
    }
  }
}


@media (max-width: 992px) {
  .article_nav {
    display: block;
    margin-bottom: 20px;

    .sidebar_nav__title {
      font-size: 18px;

      i {
        display: none;
      }
    }

    .sidebar_nav__nav {
      .sidebar_nav__item {
        .sidebar_nav__title2 {
          font-size: 16px;
        }
      }
    }

  }
  .article {

    &__course_1 {
      .courses_i {
        flex-flow: row;
        align-items: center;
        padding-left: 30px;
        padding-right: 35px;

        &__content {
          margin-left: 30px;
        }
      }
    }
  }
  .article_sidebar_2 {
    display: none;
  }
}


@media (max-width: 768px) {
  .article_nav {
    display: block;
    margin-bottom: 20px;

    .sidebar_nav__title {
      display: flex;
      align-items: center;
      justify-content: space-between;
      border: 1px solid #E9E9E9;
      border-radius: 10px;
      width: 100%;
      padding: 5px 9px;
      font-size: 18px;
      cursor: pointer;
      margin-bottom: 10px;

      i {
        display: flex;
        align-items: center;
        justify-content: center;
      }

      svg {
        width: 13px;
      }
    }

    .sidebar_nav__title__active {
      i {
        transform: rotate(180deg);
      }
    }

    .sidebar_nav {
      margin-top: 15px;
    }

    .sidebar_nav__nav {
      overflow: hidden;
      max-height: 0px;
      height: 0px;
      border: 1px solid transparent;
      padding: 0 15px;
      border-radius: 10px;

      .sidebar_nav__list {
        margin-top: 10px;
        margin-bottom: 10px;
      }

      .sidebar_nav__item {
        .sidebar_nav__title2 {
          font-size: 14px;
          padding-left: 15px;

          &::before {
            top: 7px;
            width: 4px;
            height: 4px;
          }
        }

        &:last-child {
          .sidebar_nav__title2 {
            margin-bottom: 0;
          }
        }
      }
    }

    .sidebar_nav__dropdown__active {
      .sidebar_nav__title2 {
        &::before {
          top: 4px !important;
          left: 0;
          width: 12px !important;
          height: 10px !important;
        }
      }
    }

    .sidebar_nav__dropdown {
      padding-left: 10px;

      li {
        padding-left: 15px;

        &::before {
          height: 4px;
          width: 4px;
          top: 7px;
        }
      }
    }

    .sidebar_nav__nav__active {
      border: 1px solid #E9E9E9;
      max-height: 1000px;
      height: 100%;
    }
  }
  .article {

    &__image {
      border-radius: 15px;

      .p_m_l_2 {
        padding: 3px 8px;
        font-size: 12px;
        line-height: 15px;
        bottom: 5px;
        left: 5px;
      }
    }

    &__article_inf1 {
      margin-top: 15px;
      margin-bottom: 15px;
      flex-wrap: wrap;
    }

    &__author {
      margin-right: 17px;
    }

    &__author_img {
      width: 44px;
      height: 44px;
    }

    &__author_name {
      font-size: 16px;
      line-height: 2px;
      margin-left: 12px;
    }

    &__date {
      font-size: 14px;
      line-height: 16px;
      margin-top: 10px;
      margin-bottom: 10px;
    }

    &__labels {
      margin-bottom: 10px;
    }

    &__title1 {
      font-size: 24px;
      margin-bottom: 15px;
    }

    &__title2 {
      font-size: 22px;
      margin-bottom: 15px;
    }

    &__title3 {
      font-size: 20px;
      margin-bottom: 15px;
    }

    &__p1 {
      margin-bottom: 15px;
      font-size: 14px;
    }

    &__course_1 {
      margin-top: 15px;
      margin-bottom: 15px;

      .courses_i {
        padding: 15px;
        flex-flow: column;
        align-items: flex-start;
      }

      .courses_i__text {
        font-size: 14px;
        line-height: 17px;
      }

      .courses_i__content {
        margin-left: 0px;
      }
    }

    &__note {
      border-radius: 15px;
      padding: 15px;
      margin-top: 15px;
      margin-bottom: 15px;
    }

    &__p2 {
      font-size: 14px;
    }


    &__video {
      margin-top: 15px;
      margin-bottom: 15px;

      iframe {
        height: 260px;
      }
    }

  }
  .label_05 {
    padding: 4px 9px;
    font-size: 12px;
    line-height: 16px;
    border-radius: 10px;

    span {
      padding-right: 4px;
    }
  }

  .code_sec {
    margin-top: 15px;
    margin-bottom: 15px;
    border-radius: 17px;
    padding: 10px;
  }

  .list_dots {
    margin-top: 15px;
    margin-bottom: 15px;

    li {
      font-size: 14px;
      margin-bottom: 10px;
      padding-left: 20px;

      &::before {
        width: 4px;
        height: 4px;
        border-radius: 50px;
        top: 8px;
        left: 6px;
      }

      &:last-child {
        margin-bottom: 0;
      }
    }
  }

  .list_num {
    margin-top: 15px;
    margin-bottom: 15px;
    padding-left: 15px;

    li {
      font-size: 12px;
      margin-bottom: 10px;
      padding-left: 5px;
    }

    span {
      font-size: 14px;
      padding-left: 0px;
    }
  }


  .table_1 {
    margin-top: 15px;
    margin-bottom: 15px;

    &__head {
      font-size: 14px;
      border-bottom: 1px solid #181818;
      padding: 10px 0;
    }

    &__h_item {
      &:nth-child(1) {
        width: 60%;
      }

      &:nth-child(2) {
        width: 40%;
      }

      &:nth-child(3) {
        width: 100px;
      }
    }

    &__body {
      margin-top: 10px;
    }

    &__b_line {
      border-bottom: 1px solid #E7E7E7;
      padding: 10px 0;
    }

    &__b_item {
      &:nth-child(1) {
        width: 60%;
        font-size: 14px;
        line-height: 150%;
      }

      &:nth-child(2) {
        width: 40%;
      }

      &:nth-child(3) {
        width: 100px;
        font-size: 14px;
      }
    }

    .cl_infx_none {
      font-size: 14px;
    }

    .cl_infx_01 {
      font-size: 14px;
    }
  }

  .subscribe_2 {
    padding: 17px 15px 17px 15px;
    box-shadow: 0px 4px 20px rgba(0, 0, 0, 0.05);
    backdrop-filter: blur(20px);
    border-radius: 24px;
    margin-top: 30px;
    margin-bottom: 30px;
    border-left: 3px solid #A58DFF;
    border-right: 3px solid #31C2B4;
    display: flex;
    flex-flow: column;

    &__title {
      font-size: 20px;
      max-width: 100%;
      padding-bottom: 15px;
    }

    &__sec {
      width: 100%;
    }

    &__input {
      input {
        font-size: 14px;
        height: 46px;
        padding-left: 12px;

        &::placeholder {
          font-size: 14px;
          line-height: 150%;
        }
      }
    }

    &__submit {
      padding-left: 15px;
      padding-right: 15px;
    }

    &__bottom {
      margin-top: 12px;
      display: flex;
      align-items: center;
    }

    &__social {
      li {
        width: 35px;
        height: 35px;

        a {
          svg {
            max-width: 80%;

            path {
              width: 20px !important;
            }
          }
        }
      }
    }

  }

  .maybe_interest {
    margin-top: 40px;
    padding-bottom: 50px;

    &__title {
      font-size: 24px;
      margin-bottom: 30px;
    }
  }

  .article_sidebar_2 {
    display: none;
  }

  code[class*=language-],
  pre[class*=language-] {
    font-size: 14px;
  }


  pre[class*=language-].line-numbers {
    padding-left: 1.7em;
  }

  .line-numbers .line-numbers-rows {
    top: -2px;
    font-size: 12px;
    line-height: 175%;
    left: -20px;
    width: 35px;
    letter-spacing: -1px;
  }
}


/* PrismJS 1.28.0
https://prismjs.com/download.html#themes=prism&languages=markup+css+clike+javascript */
code[class*=language-],
pre[class*=language-] {
  color: #DDC994;
  background: 0 0;
  // text-shadow: 0 1px #fff;
  font-family: 'Consolas', sans-serif, monospace;
  font-size: 16px;
  text-align: left;
  white-space: pre;
  word-spacing: normal;
  word-break: normal;
  word-wrap: normal;
  line-height: 1.5;
  -moz-tab-size: 4;
  -o-tab-size: 4;
  tab-size: 4;
  -webkit-hyphens: none;
  -moz-hyphens: none;
  -ms-hyphens: none;
  hyphens: none;
}

code[class*=language-] ::-moz-selection,
code[class*=language-]::-moz-selection,
pre[class*=language-] ::-moz-selection,
pre[class*=language-]::-moz-selection {
  text-shadow: none;
  background: #b3d4fc;
}

code[class*=language-] ::selection,
code[class*=language-]::selection,
pre[class*=language-] ::selection,
pre[class*=language-]::selection {
  text-shadow: none;
  background: #b3d4fc;
}

@media print {

  code[class*=language-],
  pre[class*=language-] {
    text-shadow: none;
  }
}

pre[class*=language-] {
  padding: 1em;
  margin: .5em 0;
  overflow: auto;
}

:not(pre) > code[class*=language-],
pre[class*=language-] {
  /* background: #f5f2f0; */
}

:not(pre) > code[class*=language-] {
  padding: .1em;
  border-radius: .3em;
  white-space: normal;
}

.token.cdata,
.token.comment,
.token.doctype,
.token.prolog {
  color: #708090;
}

.token.punctuation {
  color: #999;
}

.token.namespace {
  opacity: .7;
}

.token.boolean,
.token.constant,
.token.deleted,
.token.number,
.token.property,
.token.symbol,
.token.tag {
  color: #CB6A7B;
}

.token.attr-name {
  color: #D0AA85;
}

.token.builtin,
.token.char,
.token.inserted,
.token.selector,
.token.string {
  color: #93BC76;
}

.language-css .token.string,
.style .token.string,
.token.entity,
.token.operator,
.token.url {
  color: #747474;
  /* background: hsla(0, 0%, 100%, .5); */
}

.token.keyword {
  color: #BD77BF;
}

.token.atrule,
.token.attr-value {
  color: #93BC76;
}

.token.class-name,
.token.function {
  color: #66A6D4;
}

.token.important,
.token.regex,
.token.variable {
  color: #e90;
}

.token.bold,
.token.important {
  font-weight: 700;
}

.token.italic {
  font-style: italic;
}

.token.entity {
  cursor: help;
}


pre[class*=language-].line-numbers {
  position: relative;
  padding-left: 3.8em;
  counter-reset: linenumber
}

pre[class*=language-].line-numbers > code {
  position: relative;
  white-space: inherit
}

.line-numbers .line-numbers-rows {
  position: absolute;
  pointer-events: none;
  top: 0;

  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 149%;
  /* or 21px */

  left: -40px;
  width: 40px;
  letter-spacing: -1px;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none
}

.line-numbers-rows > span {
  display: block;
  counter-increment: linenumber
}

.line-numbers-rows > span:before {
  content: counter(linenumber);
  color: #333333;
  display: block;
  text-align: left
}


.terms-page {

  padding-top: 15px;
  padding-bottom: 80px;

  &__title {
    margin-bottom: 30px;
  }

  .text_section_01 {

  }
}

@media (max-width: 768px) {
  .terms-page {
    padding-bottom: 40px;
  }
}

.body-text {
  background: #FFFFFF !important;
}

.form-control {
  @include themify($themes) {
    background: themed('bg2') !important;
    color: themed('maincolor1') !important;
  }
}

/*icons-default-color*/
.youtube-icon-red {
  use {
    color: #FF0000 !important;
  }
}

.telegram-icon-blue {
  use {
    color: #25A3E1 !important;
  }
}

.logoIcon {
  use {
    @include themify($themes) {
      color: themed('maincolor1') !important;
    }
  }
}

#slider {
  position: relative;
  width: 86%;

  .slider-label {
    top: -50px;
    left: calc(0% - 31px);
    position: absolute;
    width: 80px;
    height: 30px;
    border-radius: 4px;
    padding: 15px;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 18px;
    line-height: 21px;
    color: #009688;
    font-weight: 600;
    @include themify($themes) {
      background: themed('bgSlider') !important;
    }
    border: 1px solid rgba(255, 255, 255, 0.4);
    box-shadow: 0px 4px 20px rgba(0, 0, 0, 0.05);
    backdrop-filter: blur(10px);
  }
}

#slider input {
  -webkit-appearance: none;
  width: 100%;
  height: 6px;
  background: #fafafa;
  outline: none;
  opacity: 0.7;
  -webkit-transition: .2s;
  transition: opacity .2s;
}

#slider input::-webkit-slider-thumb {
  -webkit-appearance: none;
  appearance: none;
  width: 18px;
  height: 18px;
  background: #009688;
  cursor: pointer;
  border-radius: 50%;

}

#slider input::-moz-range-thumb {
  width: 18px;
  height: 18px;
  background: #009688;
  cursor: pointer;
  border-radius: 50%;
}

/*leave modal*/
#leave-modal {
  display: none;
  justify-content: center;
  align-items: center;
  position: fixed;
  z-index: 1;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  overflow: auto;
  padding: 15px;
  background-color: rgb(0, 0, 0);
  background-color: rgba(0, 0, 0, 0.4);
  .modal-content {
    @include themify($themes) {
      background-color: themed('bodybg');
    }
    width: 600px;
    height: 600px;
    display: flex;
    flex-direction: column;
    @media (max-width: 768px) {
      width: 300px;
      height: 300px;
    }
    @media (max-width: 375px) {
      width: 100%;
    }
    .modal-content-header {
      padding: 0px 15px 0px 15px
    }

    .modal-content-body {
      padding: 15px;
      flex: 1;
      @include themify($themes) {
        color: themed('maincolor1');
      }
    }

    .modal-content-footer {
      padding: 30px;
      display: flex;
      justify-content: flex-end;
      gap: 20px;

      #leave-modal-accept {
        width: 150px;
        height: 44px;
        border: none;
      }

      #leave-modal-reject {
        width: 150px;
        height: 44px;
        border: none;
      }
    }
  }

  /* The Close Button */
  .close {
    color: #aaa;
    float: right;
    font-size: 28px;
    font-weight: bold;
    width: 20px;
    height: 30px;
  }

  .close:hover,
  .close:focus {
    color: black;
    text-decoration: none;
    cursor: pointer;
  }
}

/* markdown extensions */

.admonition, details {
  padding: 15px;
  margin-bottom: 20px;
  border: 1px solid transparent;
  border-radius: 4px;
  text-align: left;
}

.admonition.note, details.note { /* csslint allow: adjoining-classes */
  color: #2e6b89;
  background-color: #e2f0f7;
  border-color: #bce8f1;
}

.admonition.warning, details.warning { /* csslint allow: adjoining-classes */
  color: #7a6032;
  background-color: #fffae5;
  border-color: #fbeed5;
}

.admonition.danger, details.danger { /* csslint allow: adjoining-classes */
  color: #7f3130;
  background-color: #fde3e3;
  border-color: #eed3d7;
}

.admonition-title, summary {
  font-weight: bold;
  text-align: left;
}

.admonition > p:last-child, details > p:last-child {
  margin-bottom: 0;
}

/* markdown styles */

del {
  color: #999;
}

blockquote {
  margin: 14px 0;
  border-left: 4px solid #e5e5e5;
  padding: 0 16px;
  color: #999;
}

.content-body{
  a{
    border-bottom: 1px solid rgba(89,67,182,.24);
    text-decoration: none;
    font-style: normal;
    font-size: inherit;
    text-rendering: auto;
    -webkit-font-smoothing: antialiased;
  }

  a[href*='/uploads/']::before, a[href*='cdn.pylot.me/']::before {
    margin-right: 4px;
    content: '📎'
  }

  a[href*='/author/']::before {
    margin-right: 4px;
    content: '👤'
  }

  a[href*='/course/']::before {
    margin-right: 4px;
    content: '🎓'
  }

  [href^='http://']:not([href*='pylot.me'])::after {
    padding-left: 5px;
    content: '🔗';
  }

}

.nav-link{
  transition: none;
}

/* tables in content */

.toastui-editor-main-container, .article_page{
  table {
    border: 1px solid rgba(0, 0, 0, 0.1);
    margin: 12px 0 14px;
    @include themify($themes) {
      color: themed('maincolor1');
    }
    width: auto;
    border-collapse: collapse;
    box-sizing: border-box;
  }

  table th,
  table td {
    border: 1px solid rgba(0, 0, 0, 0.1);
    padding: 5px 14px 5px 12px;
    height: 32px;
  }

  table th {
    background-color: #555;
    font-weight: 300;
    color: #fff;
    padding-top: 6px;
  }

  th p {
    margin: 0;
    color: #fff;
  }

  td p {
    margin: 0;
    padding: 0 2px;
  }

  .highlight{
    &::before {
      font-size: 12px;
      display: block;
      position: relative;
      top: 0;
      left: 0;
      width: 100%;
      background: #d8ebda;
      padding: 0;
      text-indent: 0.5em;
      padding: 0;
      border-top-left-radius: 3px;
      border-top-right-radius: 3px;
      color: #5ea354;
      font-weight: 400;
    }

    td, tr {
      padding-left: 5px;
      padding-right: 5px;
      border: none;
    }
  }

  .highlighttable {
    border: none;
  }
}

pre {
  font-size: 1em;
}

.toc {
  line-height: 1.3;
}

details {
  display: block;
}

details[open] > summary::before {
  content: "\25BC";
}

details summary {
  display: block;
  cursor: pointer;
}

details summary:focus {
  outline: none;
}

details summary::before {
  content: "\25B6";
  padding-right: 0.5em;
}

details summary::-webkit-details-marker {
  display: none;
}

/* Attach the "no-details" class to details tags
   in browsers that do not support them to get
   open/show functionality. */
details.no-details:not([open]) > * {
  display: none;
}

details.no-details:not([open]) summary {
  display: block;
}
.select2-dropdown {
  @include themify($themes) {
    background: themed('bgDropdown') !important;
    color: themed('maincolor1') !important;
  }

}
.select2-results__option--selected{

  @include themify($themes) {
    background: themed('hoverbgDropdown') !important;
    color: themed('maincolor1') !important;
  }
}
.category-widget {
  margin-bottom: 20px;
  .category-widget-label {
    margin-bottom: 10px;
  }

  .select2-container {
    width: 100% !important;
    min-height: 42px;

    textarea {
      line-height: 21px;
      margin: 0;
      height: 21px !important;
      @include themify($themes) {
        color: themed('maincolor1') !important;
      }
    }
    .select2-selection {
      border-radius: 2px;
      border: 1px solid #ced4da;
      min-height: 42px;
      padding: 5px;
      display: flex;
      flex-wrap: wrap;
      align-items: center;
      overflow-x: hidden;
      ul {
        margin-bottom: 0;
      }
      @include themify($themes) {
        background: themed('bg2') !important;
        color: themed('maincolor1') !important;
      }

      .select2-selection__rendered {
        display: none;
      }
      .tag-selected-container {
        display: flex;
        flex-wrap: wrap;
        gap: 10px;
        padding: 5px;
        .tag-selected {
          display: flex;
          gap: 5px;
          list-style-type: none;
          align-items: center;
          padding: 5px 10px 5px 10px;
          border-radius: 10px;
          color: #FFFFFF;
          .favorite-tag-selected {
            display: flex;
            align-items: center;
          }
          .favorite-tag-selected:hover {
            cursor: pointer;
          }
          .destroy-tag-selected:hover {
            cursor: pointer;
          }
        }
      }
    }
  }
}

#cookie-policy {
  display: none;
  position: fixed;
  bottom: 0;
  left: 0;
  justify-content: flex-start;
  align-items: flex-end;
  z-index: 100;

  .cookie-container {
    @include themify($themes) {
      background: themed('maincolor1');
      color: themed('color2');
    }
    border-radius: 10px;
    max-width: 400px;
    opacity: 0.95;
    margin: 30px;
    padding: 30px;
    @media (max-width: 767px) {
      max-width: unset;
      width: 100%;
      margin: 0;
      border-radius: 10px 10px 0px 0px;
    }
    .cookie-title {

    }
    .cookie-actions {
      display: flex;
      gap: 20px;
      padding-top: 20px;
      flex-wrap: wrap;
      .cookie-more {
        padding: 10px 20px 10px 20px;
        border-radius: 4px;
        text-decoration: none;
        @include themify($themes) {
          color: themed('color2');
          background: themed('bg14');
        }
      }
      .cookie-agree{
        padding: 10px 20px 10px 20px;
        border-radius: 4px;
        text-decoration: none;
        @include themify($themes) {
          color: themed('color2');
          background: themed('bg14');
        }
      }
    }
  }
}

.swiper-container {
  position: relative;
  overflow: hidden;
}
.swiper-container,
.swiper-wrapper {
  padding: 0;
  margin: 0;
  width: 100%;
  height: 100%;
}
.swiper-slide {
  position: relative;
  display: flex;
  flex-shrink: 0;
  justify-content: center;
  width: 100%;
  height: 100%;
  font-size: 18px;
  cursor: grab;
  flex-direction: column;
  align-items: flex-start;
}
